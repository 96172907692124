import React from 'react';
// import ajax from '../utils/ajax';
// import dictionary from '../../../utils/dictionary';
import functions from '../../utils/functions';
import ActivityLoader from '../../components/common/ActivityLoader';
import AlertError from '../../shared/AlertError';
import AlertSuccess from '../../shared/AlertSuccess';
import TextInput from '../../components/common/TextInput';
import ajaxPayslips from '../../utils/remote/ajaxPayslips';
import ajaxUser from '../../utils/remote/ajaxUser';
// import ajaxLocation from '../utils/remote/ajaxLocation';

class PopImportUsers extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        title: '',
        fileName: '',
        actualCsvFile: '',
        info: '',
        loading: false
    }

    onChangeTitle = (event) => {
        this.setState({ title: event.target.value })
    }

    onChangeFile = (event) => {
        this.setState({
            fileName: event.target.value
        })
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.setState({
                actualCsvFile: { file: e.target.result }
            })
        }

    }


    onConfirm = async (event) => {
        event.preventDefault();
        const { fileName, actualCsvFile } = this.state;
        if (fileName.length > 0) {
            this.setState({
                loading: true
            })

            const server_response = await ajaxUser.importUsers(actualCsvFile);
            
            this.setState({
                loading: false
            })
            if (server_response.status == "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />,
                    fileName: '',
                    actualCsvFile: ''
                }
                     , () => this.props.refresh()
                )
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={"Select CSV file and try again"} />
            })
        }
    }





    render() {

        return (
            <div className="modal fade" id="modal_import_users">
                <div className="modal-dialog modal-dialog-centered" role="document">


                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h5 className="modal-title font-weight-bold">Import Users</h5>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>
                            <form method="post" onSubmit={this.onConfirm}>



                                {/* <TextInput
                                    label="Selected List"
                                    type="text"
                                    onChange={() => this.onChangeTitle}
                                    value={this.props.list.title}
                                    readOnly={true}
                                /> */}

                                <TextInput
                                    label="Select CSV file"
                                    type="file"
                                    onChange={() => this.onChangeFile}
                                    value={this.state.fileName}
                                />



                            </form>


                        </div>
                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-purple" type="button" onClick={this.onConfirm}>Import</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>


                </div>
            </div>
        )


    }
}

export default PopImportUsers;
