import React from 'react'
import TableHeader from '../../shared/TableHeader'
// import { Link } from 'react-router-dom'


class Ratings extends React.Component {

    render() {
        return (

            <div className=" col-sm-12 grid-margin">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title mb-3">Overall rating</h4> */}

                        <TableHeader
                            title="Overall rating"
                            subtitle="A representation of the recent rating activity."
                            // view="View All"
                            // onClick={this.props.onClickViewAll}
                        />

                        <div className="d-flex">
                            <div>
                                <h4 className="text-dark font-weight-bold mb-2 mr-2">4.3</h4>
                            </div>
                            <div>
                                <select id="over-all-rating" name="rating" autoComplete="off">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                        </div>
                        <p className="mb-4">Based on 186 reviews</p>
                        <div className="row">
                            <div className="col-sm-2 pr-0">
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">5</div>
                                    </div>
                                    <div>
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 pl-2">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="progress progress-lg mt-1">
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "80%" }}
                                                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 p-lg-0">
                                        80%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-2 pr-0">
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">4</div>
                                    </div>
                                    <div>
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 pl-2">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="progress progress-lg mt-1">
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "45%" }}
                                                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 p-lg-0">
                                        45%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-2 pr-0">
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">3</div>
                                    </div>
                                    <div>
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 pl-2">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="progress progress-lg mt-1">
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "30%" }}
                                                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 p-lg-0">
                                        30%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-2 pr-0">
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">2</div>
                                    </div>
                                    <div>
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 pl-2">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="progress progress-lg mt-1">
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "8%" }} aria-valuenow="25"
                                                aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 p-lg-0">
                                        8%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-2 pr-0">
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">5</div>
                                    </div>
                                    <div>
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 pl-2">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="progress progress-lg mt-1">
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: "1%" }} aria-valuenow="25"
                                                aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 p-lg-0">
                                        1%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="mb-2 mt-3 mb-3 text-dark font-weight-bold">Rating by category</p>
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">4.3</div>
                                    </div>
                                    <div className="mr-2">
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                    <div>
                                        <p>Work/Management</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <div className="text-dark font-weight-bold mb-2 mr-2">3.5</div>
                                    </div>
                                    <div className="mr-2">
                                        <i className="fa fa-star text-warning"></i>
                                    </div>
                                    <div>
                                        <p>Salary/Culture</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

}

export default Ratings
