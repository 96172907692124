import React, { useContext } from 'react';
import NavBar from '../shared/navbar/NavBar';
import Footer from '../shared/footer/Footer';
import SideBar from '../shared/sidebar/SideBar';
import Title from '../shared/Title';
// import Ratings from '../components/dashboard/Rating';
import ajaxPayslips from '../utils/remote/ajaxPayslips';
// import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
// import PageNav from '../shared/PageNav';
import TableHeader from '../shared/TableHeader';
// import { Link } from 'react-router-dom';
import ActivitySpinner from '../components/common/ActivitySpinner';
import PopRecord from '../components/pops/PopRecord';
// import $ from 'jquery';
// import DashCard from '../components/dashboard/cards/DashCard';
import dictionary from '../utils/dictionary';
// import RecentActivity from '../components/dashboard/RecentActivity';
import ThemeContext from '../Context/ThemeContext';
import NoContent from '../components/common/NoContent';
import PopImportPayslips from '../components/pops/PopImportPayslips';
import ActivityLoader from '../components/common/ActivityLoader';
import PageNav from '../shared/PageNav';


const eformat = dictionary._exportBtnFormats


class Payslips extends React.Component {

    static contextType = ThemeContext;


    state = {
        userId: functions.sessionGuard(),

        payslipList: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        recordView: false,
        noOfRecords: 8,

        payslipRecord: '',
        selectedPayslipId: '',

        metaData: false,
        currentPage: 1,
        pagination: false,
        modal: false,

        countActivePayslips: '...',
        tableID: "table-payslips"
    }

    componentDidMount() {

        const { tableDetected, refreshDatatable, setTableID } = this.context

        // const onClickTableTools = () => {



        setTableID(this.state.tableID)
        // if (tableDetected){
        //     refreshDatatable()

        // }

        // if (this.props.location.state) {
        //     this.setState({
        //         status: this.props.location.state.status
        //     }, () => this.listPayslips())
        // } else {
        //     this.listPayslips();
        // }

        // this.listUnpaginatedPayslips();
        this.listPayslips();
    }

    // componentWillUnmount() {
    //     // clearTimeout(this.timerCountPayslips)
    // }


    listPayslips = async () => {
        this.setState({ pagination: false })
        const server_response = await ajaxPayslips.listPayslips(this.state.status, this.state.currentPage);
        if (server_response.status === "OK") {
            this.setState({
                payslipList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })
        } else {
            this.setState({
                payslipList: "404",
                metaData: false
            })
        }
    }

    // listUnpaginatedPayslips = async () => {
    //     const server_response = await ajaxPayslips.listSelectionPayslips();
    //     if (server_response.status === "OK") {
    //         this.setState({
    //             payslipList: server_response.details.content.list,
    //             metaData: server_response.details.content.meta
    //         })
    //     } else {
    //         this.setState({
    //             payslipList: "404",
    //             metaData: false
    //         })
    //     }
    // }

    listAllPayslips = async () => {
        if (this.state.filter) {
            const filter = this.state.filter;
            var server_response = await ajaxPayslips.filterPayslips(
                filter.dateFrom,
                filter.dateTo,
                // filter.selectedValue,
                filter.keyword,
                this.state.currentPage,
                this.state.status
            );

        } else {
            var server_response = await ajaxPayslips.listPayslips(this.state.status, this.state.currentPage);
        }

        if (server_response.status === "OK") {
            this.setState({
                payslipList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            }, () => this.paginate())

        } else {
            this.setState({
                payslipList: "404",
                metaData: false
            })
        }
    }

    //===== NAVIGATION CONTROLS START ========

    onClickPage = (page) => {
        this.setState({
            currentPage: page,
            payslipList: false
        }, () => this.listPayslips())
    }

    onClickNext = () => {
        //increment page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
            this.setState({
                currentPage: this.state.currentPage + 1,
                payslipList: false
            }, () => {
                this.listPayslips();
            })
        }
    }

    onClickPrevious = () => {
        //decrement page numbers
        const metaData = this.state.metaData;
        if (metaData.current_page * 1 > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1,
                payslipList: false
            }, () => {
                this.listPayslips();
            })
        }
    }

    paginate = () => {
        if (this.state.metaData) {
            this.setState({
                pagination: false
            }, () => {
                this.setState({
                    pagination: <PageNav
                        totalPages={this.state.metaData.total_pages}
                        pageList={this.state.metaData.list_of_pages}
                        currentPage={this.state.currentPage}
                        onClickNext={this.onClickNext}
                        onClickPage={this.onClickPage}
                        onClickPrevious={this.onClickPrevious}
                    />
                })
            })
        }
    }

    //===== NAVIGATION CONTROLS END ========

    onFilter = async (filter) => {
        this.setState({
            filter: false,
            payslipList: false,
            metaData: false
        }, () => {
            this.setState({ filter: filter }, () => this.listAllPayslips())
        })
    }

    resetFilter = () => {
        this.setState({
            filter: false,
            payslipList: false
        }, () => this.listAllPayslips())
    }


    

    openRecord = (record) => {
        this.setState({
            recordView: false
        }, () => {

            this.setState({
                recordView: <PopRecord record={record} />
            }, () => {
                functions.openPopup("exampleModalCenter")
            })

        })
    }

    editRecord = (payslipRecord) => {
        if (this.state.payslipRecord) {
            this.props.history.push({
                pathname: '/payslips/update',
                search: '?q=1',
                state: { record: this.state.payslipRecord }
            })
        }
    }

    onSelectRecord = (selectedPayslipId, payslipRecord, key) => {
        this.setState({
            selectedPayslipId: selectedPayslipId,
            payslipRecord: payslipRecord,
            toast: '',
            activeKey: key
        }, () => {
            console.log(this.state.payslipRecord)
        })
    }

    onClickImportPayslips = () => {
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopImportPayslips
                    refresh={this.listPayslips}
                />
            }, () => functions.openPopup('modal_import_payslips'))
        })
    }


    render() {

        const payslipList = this.state.payslipList;
        // console.log(payslipList)
        // const filter = this.state.filter

        return (

            <div className="container-scroller">
                <NavBar />

                <div className="container-fluid page-body-wrapper">
                    <SideBar />

                    <div className="main-panel">
                        <div className="content-wrapper">

                            <Title
                                title="System Payslips"
                                description="JANUARY 20, 2022"
                                previousPageTitle="Dashboard"
                                back="/"
                                import={this.onClickImportPayslips}
                                add="Add Payslip"
                                icon="fas fa-file"
                                link="/payslips/new"
                            />

                            {this.state.recordView}
                            {this.state.modal}

                            <div className="row mt-3">
                                <div className="col-xl-12 grid-margin-lg-0 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">

                                            <TableHeader
                                                title="All System Payslips"
                                                subtitle="A tabular representation of the payslips."
                                                // view="Add Payslip"
                                                // link="/payslips/new"
                                            // onClick={this.onClickAddPayslip}
                                            />

                                            {/* <TableHeader
                                                title="All system payslips"
                                                subtitle="A tabular representation of the payslips."
                                                view="Export to pdf"
                                                onClick={this.onClickAddPayslip}
                                            /> */}



                                            <div className="table-responsive allView-table mt-3">
                                                <table id={this.state.tableID} className="table table-hover text-nowrap table-header-bg datatable " export-format={eformat}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th>Payslip Ref</th> */}
                                                            {/* <th>Hotel ID</th> */}
                                                            <th>Staff Name</th>
                                                            {/* <th>Week one</th>
                                                            <th>Week two</th>
                                                            <th>Week three</th>
                                                            <th>Week four</th>
                                                            <th>Week five</th> */}
                                                            <th>Total days worked</th>
                                                            <th>Amount per day (UGX)</th>
                                                            <th>Gross Amount (UGX)</th>
                                                            <th>NSSF (5%)</th>
                                                            <th>Paye</th>
                                                            <th>LST</th>
                                                            <th>ADV</th>
                                                            <th>UNION FEE</th>
                                                            <th>NET PAY</th>
                                                            <th>Payment Month</th>
                                                            <th>Created On</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {payslipList && payslipList !== "404" &&
                                                            payslipList.map((item, key) =>
                                                                <tr
                                                                    key={key}
                                                                    id={"slip_" + item.payslip_id}
                                                                    className={`${this.state.selectedPayslipId === item.payslip_id ? 'flag-row' : ''}`}
                                                                    onClick={() => this.onSelectRecord(item.payslip_id, item, key)}
                                                                >
                                                                    {/* <td>B220202115200R9</td> */}
                                                                    {/* <td>{item.user.username}</td> */}
                                                                    <td>
                                                                        <p className='font-weight-bold'>{item.user.full_name} </p>
                                                                        <small className='tx-12 tx-gray-500'> {item.user.username}</small>
                                                                    </td>
                                                                    {/* <td>{item.week1}</td>
                                                                    <td>{item.week2}</td>
                                                                    <td>{item.week3}</td>
                                                                    <td>{item.week4}</td>
                                                                    <td>{item.week5}</td> */}
                                                                    <td>{item.total_days}</td>
                                                                    <td>{item.amount_per_day}</td>
                                                                    <td className='font-weight-bold'>{item.gross_pay}</td>
                                                                    <td>{item.nssf_deduction}</td>
                                                                    <td>{item.paye_deduction}</td>
                                                                    <td>{item.lst_deduction}</td>
                                                                    <td>{item.adv_deduction}</td>
                                                                    <td>{item.union_fee_deduction}</td>
                                                                    <td className='font-weight-bold'>{item.net_pay}</td>
                                                                    <td>{item.created_for === 'NULL' ? <small className="tx-12 tx-gray-500">No Month</small> : item.created_for}</td>
                                                                    <td>
                                                                        <p>{item.created_at.short_date} </p>
                                                                        <small className='tx-12 tx-gray-500'> {item.created_at.time}</small>
                                                                    </td>
                                                                    <td>
                                                                        {this.state.selectedPayslipId === item.payslip_id &&
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn ripple btn-secondary btn-sm see-all-btn mr-2"
                                                                                    onClick={() => this.openRecord(item)}
                                                                                >
                                                                                    <i className="icon-eye menu-icon"></i>
                                                                                </button>


                                                                                <button
                                                                                    type="button"
                                                                                    className="btn ripple btn-purple btn-sm see-all-btn"
                                                                                    onClick={() => this.editRecord(item)}
                                                                                >
                                                                                    <i className="fas fa-edit menu-icon"></i>
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                        {/* <tr>
                                                            <td>B220202115200R9</td>
                                                            <td>H-270</td>
                                                            <td>
                                                                <p className='font-weight-bold'>Dave Kresinski</p>
                                                                <small className='text-gray'>@dave</small>
                                                            </td>
                                                            <td>4</td>
                                                            <td>2</td>
                                                            <td>5</td>
                                                            <td>6</td>
                                                            <td>4</td>
                                                            <td>20</td>
                                                            <td>12,000</td>
                                                            <td>240,000</td>
                                                            <td>12,000</td>
                                                            <td>500</td>
                                                            <td>2500</td>
                                                            <td></td>
                                                            <td>5000</td>
                                                            <td>220,000</td>
                                                            <td>May 15, 2015</td>
                                                            <td><label className="badge badge-warning">Pending</label></td>
                                                        </tr> */}

                                                        {/* <tr>
                                                            <td>H-270</td>
                                                            <td>
                                                                <p className='font-weight-bold'>Peter Coach</p>
                                                                <small className='text-gray'>@dave</small>
                                                            </td>
                                                            <td>4</td>
                                                            <td>2</td>
                                                            <td>5</td>
                                                            <td>6</td>
                                                            <td>4</td>
                                                            <td>20</td>
                                                            <td>12,000</td>
                                                            <td>240,000</td>
                                                            <td>12,000</td>
                                                            <td>500</td>
                                                            <td>2500</td>
                                                            <td></td>
                                                            <td>5000</td>
                                                            <td>220,000</td>
                                                            <td>May 15, 2015</td>
                                                            <td><label className="badge badge-success">Paid</label></td>
                                                        </tr> */}




                                                    </tbody>
                                                </table>

                                                {this.state.metaData && this.state.pagination}

                                                <div className="pagination-center">
                                                    {!payslipList && <ActivityLoader />}
                                                    {payslipList === "404" && <NoContent />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-xl-3 p-0">
                                    <div className="d-flex justify-content-between flex-column">

                                        <DashCard
                                            name="Total Payslips"
                                            icon="fas fa-book"
                                            color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                                            percentage="All payslips generated"
                                            value="1,200"
                                            href="/payslips"
                                        />

                                        <RecentActivity />
                                    </div>
                                </div> */}

                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

}

export default Payslips;
