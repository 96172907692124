import React from 'react'
import { Link } from 'react-router-dom'


class DashCard extends React.Component {

    render() {
        return (
            <>
                {/* <div className="col-sm-12 dashcard">
                <Link to={this.props.href}>
                    <div className="card custom-card pinned-card">
                        <div className="card-body d-flex ">
                            <div className={this.props.color}>
                                <i className={this.props.icon}></i>
                            </div>
                            <div className="ml-4">
                                <p className="mb-2 text-muted">{this.props.name}</p>
                                <h3 className="mb-0">{this.props.value}</h3>
                            </div>
                        </div>
                    </div>
                </Link>
            </div> */}

                <div className={`${this.props.size ? this.props.size : 'col-sm-12'} grid-margin dashcard`}>
                    <Link to={this.props.href}>
                        <div className="card">
                            <div className="card-body d-flex align-items-center">
                                <div className={this.props.color}>
                                    <i className={this.props.icon}></i>
                                </div>
                                <div className="ml-4">
                                    <h5 className="card-title">{this.props.name}</h5>
                                    <p className="mb-2 text-muted">{this.props.percentage}</p>
                                    <h4 className="text-dark font-weight-bold">{this.props.value}</h4>
                                    {/* <canvas id="orders"></canvas> */}
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </>
        )
    }

}

export default DashCard
