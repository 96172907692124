import React from 'react';
import { Link } from 'react-router-dom';

import profile_photo from '../../assets/avatar.svg'
// import logo from '../../assets/logo-white.svg';
import ajaxUser from '../../utils/remote/ajaxUser';
import functions from '../../utils/functions';


class SideBar extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: '',
        holder: 'USER',
        subscript: 'role',
    }

    componentDidMount() {
        this.getUser();
    }


    getUser = async () => {
        const server_response = await ajaxUser.userInfo(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.first_name + ' ' + server_response.details.content.last_name,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path
            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }

    render() {

        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                                    
                <div className="user-profile mt-5">
                    <div className="user-image mt-5">
                        <img src={profile_photo} alt="profile" />
                    </div>
                    <div className="user-name mt-3">
                        {this.state.username ? this.state.username : this.state.holder}
                    </div>
                    <div className="user-designation">
                        {this.state.roleName ? this.state.roleName : this.state.subscript}
                    </div>
                </div>
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">
                            <i className="fas fa-th-large menu-icon"></i>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/finance">
                            <i className="fas fa-wallet menu-icon"></i>
                            <span className="menu-title">Finance</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        {/* <Link className="nav-link" to="/users">
                            <i className="icon-head menu-icon"></i>
                            <span className="menu-title">Users</span>
                        </Link> */}
                        <a className="nav-link" data-toggle="collapse" href="#user-basic" aria-expanded="false" aria-controls="ui-basic">
                        <i className="fas fa-user menu-icon"></i>
                            <span className="menu-title">Users</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="user-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/fld-users">Field users</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/sys-users">System users</Link></li>
                                {/* <li className="nav-item"> <Link className="nav-link" to="/users">All users</Link></li> */}
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/payslips">
                            <i className="fas fa-file menu-icon"></i>
                            <span className="menu-title">Payslips</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                            <i className="fas fa-cog menu-icon"></i>
                            <span className="menu-title">Configurations</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <Link className="nav-link" to="/users">Tax constants</Link></li>
                                {/* <li className="nav-item"> <Link className="nav-link" to="/payslips">Payslips</Link></li> */}
                            </ul>
                        </div>
                    </li>
                     

                    
                    
                </ul>
            </nav>
        )

    }
}

export default SideBar;
