import React from 'react';
import NavBar from '../../shared/navbar/NavBar';
import Footer from '../../shared/footer/Footer';
import SideBar from '../../shared/sidebar/SideBar';
import Title from '../../shared/Title';
// import DashCard from '../components/dashboard/cards/DashCard';
// import BarChart from '../components/dashboard/charts/BarChart';
// import RecentActivity from '../components/dashboard/RecentActivity';
// import RecentPayslips from '../components/dashboard/RecentPayslips';
// import Ratings from '../components/dashboard/Rating';
// import ajaxPayslips from '../utils/remote/ajaxPayslips';
// import dictionary from '../utils/dictionary';
import functions from '../../utils/functions';
// import PageNav from '../shared/PageNav';
import TableHeader from '../../shared/TableHeader';
import { Link } from 'react-router-dom';
// import ActivitySpinner from '../components/common/ActivitySpinner';
// import PopImage from '../components/pops/PopRecord';
// import $ from 'jquery';
// import DashCard from '../components/dashboard/cards/DashCard';
import dictionary from '../../utils/dictionary';
// import RecentActivity from '../components/dashboard/RecentActivity';
import ajaxUser from '../../utils/remote/ajaxUser';

// import avatar from '../assets/avatar.svg';
import NoContent from '../../components/common/NoContent';
import active from '../../assets/online.svg';
import inactive from '../../assets/offline.svg';
// import PopRecord from '../components/pops/PopRecord';
// import PopImportPayslips from '../components/pops/PopImportPayslips';
import PopImportUsers from '../../components/pops/PopImportUsers';
import ActivityLoader from '../../components/common/ActivityLoader';
// import PageHeader from '../shared/PageHeader';

// import Banner from '../../components/Home/banner/banner';
// import Header from '../../shared/header/Header';
// import Description from '../../components/Home/description/description';
// import Footer from '../../shared/footer/Footer';
// import Cards from '../../components/Home/cards/cards';
// import Testimony from '../../components/Home/testimonials/testimonials';
// import Testimonial from '../../components/Home/testimonials/Testimonial';
// import Contacts from '../../shared/contact/contact';


const eformat = dictionary._exportBtnFormats


class FieldUsers extends React.Component {

    state = {
        userId: functions.sessionGuard(),

        userList: false,
        userCount: '...',
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        imageView: false,
        noOfRecords: 8,

        userRecord: '',
        selectedUserId: '',

        metaData: false,
        currentPage: 1,
        pagination: false,
        modal: false,

        countActivePayslips: '...',
        tableID: "table-users"
    }

    componentDidMount() {
        // if (this.props.location.state) {
        //     this.setState({
        //         status: this.props.location.state.status
        //     }, () => this.listAllUsers())
        // } else {
        //     this.listAllUsers();
        // }

        this.listUnpaginatedUsers();
    }

    componentWillUnmount() {
        // clearTimeout(this.timerCountUsers)
    }


    // listUsers = async () => {
    //     const server_response = await ajaxUser.listUsers(this.state.status, this.state.currentPage);
    //     if (server_response.status === "OK") {
    //         this.setState({
    //             userList: server_response.details.content.list,
    //             metaData: server_response.details.content.meta
    //         }, () => this.paginate())
    //     } else {
    //         this.setState({
    //             userList: "404",
    //             metaData: false
    //         })
    //     }
    // }

    onSelectRecord = (selectedUserId, userRecord, key) => {
        this.setState({
            selectedUserId: selectedUserId,
            userRecord: userRecord,
            toast: '',
            activeKey: key
        })
    }


    listUnpaginatedUsers = async () => {
        const server_response = await ajaxUser.listSelectionFieldUsers();
        // console.log(server_response)
        if (server_response.status === "OK") {
            this.setState({
                userList: server_response.details.content,
                metaData: server_response.details.content.meta
            })
        } else {
            this.setState({
                userList: "404",
                metaData: false
            })
        }
    }

    editRecord = (userRecord) => {
        // this.setState({
        //     recordView: false
        // }, () => {

        //     this.setState({
        //         recordView: <PopRecord record={record} />
        //     }, () => {
        //         functions.openPopup("exampleModalCenter")
        //     })

        // })

        if (this.state.userRecord) {
            this.props.history.push({
                pathname: '/users/update',
                search: '?q=1',
                state: { record: this.state.userRecord }
            })
        }
    }


    onClickImportUsers = () => {
        // const { getLocations } = this.context;
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopImportUsers
                    refresh={this.listUnpaginatedUsers}
                />
            }, () => functions.openPopup('modal_import_users'))

        })
    }


    render() {

        const usersList = this.state.userList;

        return (

            <div className="container-scroller">
                <NavBar />

                <div className="container-fluid page-body-wrapper">
                    <SideBar />

                    <div className="main-panel">
                        <div className="content-wrapper">

                            <Title
                                title="Field Users"
                                description="JANUARY 20, 2022"
                                previousPageTitle="Dashboard"
                                back="/"
                                import={this.onClickImportUsers}
                                add="Add User"
                                icon="fas fa-user"
                                link="/users/new"
                            />

                            {this.state.imageView}
                            {this.state.modal}

                            <div className="row mt-3">
                                <div className="col-xl-12 grid-margin-lg-0 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">

                                            <TableHeader
                                                title="All Field Users"
                                                subtitle="A tabular representation of the all the field staff."
                                                // view="Add User"
                                                // onClick={this.onClickAddUser}
                                                // link="/users/new"
                                            />

                                            <div className="table-responsive allView-table mt-3">
                                                <table id={this.state.tableID} className="table table-hover text-nowrap table-header-bg datatable" export-format={eformat}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th>Payslip Ref</th> */}
                                                            {/* <th>ID</th> */}
                                                            {/* <th></th> */}
                                                            <th>Staff Name</th>
                                                            <th>Role</th>
                                                            <th>Phone Number</th>
                                                            {/* <th>Date Of Birth</th> */}
                                                            {/* <th>Email Address</th> */}


                                                            <th>Union Subscription</th>
                                                            <th>Branch</th>

                                                            <th>Amount Per Day</th>
                                                            {/* <th>Tin Number</th> */}
                                                            {/* <th>Status</th> */}
                                                            <th>Created On</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {usersList && usersList !== "404" &&
                                                            usersList.map((item, key) =>
                                                                <tr
                                                                    key={key}
                                                                    id={"usr_" + item.user_id}
                                                                    className={`${this.state.selectedUserId === item.user_id ? 'flag-row' : ''}`}
                                                                    onClick={() => this.onSelectRecord(item.user_id, item, key)}
                                                                >
                                                                    {/* <td>{item.user_id}</td> */}

                                                                    {/* <td className="pointer">
                                                                        <div className="main-img-package">
                                                                            <img alt="avatar" src={avatar} />
                                                                        </div>
                                                                    </td> */}

                                                                    <td>
                                                                        <p className='font-weight-bold'>{item.full_name} </p>
                                                                        <small className="tx-12 tx-gray-500"> @{item.username}</small>
                                                                    </td>
                                                                    <td className='text-wrap'>{item.user_role.role_name}</td>
                                                                    <td >{item.phone_number}</td>

                                                                    {/* <td>{item.date_of_birth}</td> */}


                                                                    {/* <td className='text-wrap'>{item.email !== 'NULL' ? item.email : <small className="tx-12 tx-gray-500">No Email</small>}</td> */}
                                                                    {/* <td className="cell_size">{item.branch}</td> */}



                                                                    {/* <td>{item.is_union_subscribed}</td> */}
                                                                    <td>
                                                                        {item.is_union_subscribed === "1" && <span className="badge badge-success">Subscribed</span>}
                                                                        {item.is_union_subscribed === "0" && <span className="badge badge-primary">Unsubscribed</span>}
                                                                    </td>
                                                                    <td>{item.branch ? item.branch.branch_name : <span className='tx-12 tx-gray-500'>Not attached</span>}</td>
                                                                    <td className='font-weight-bold'>{item.amount_per_day}</td>
                                                                    {/* <td>{item.tin_number === 'NULL' || item.tin_number === '0' ? <small className="tx-12 tx-gray-500">No TIN</small> : item.tin_number}</td> */}
                                                                    {/* <td>
                                                                        {item.status === "1" &&
                                                                            <>
                                                                                <i className="task-icon-user m-0 bg-purple mr-2"></i>
                                                                                <img className="task-icon-user m-0 mr-1" src={active} alt="active" />
                                                                                <span>Active</span>
                                                                            </>
                                                                        }
                                                                        {item.status === "0" &&
                                                                            <>
                                                                                <i className="task-icon-user m-0 bg-purple mr-2"></i>
                                                                                <img className="task-icon-user m-0 mr-1" src={inactive} alt="active" />
                                                                                <span>Inactive</span>
                                                                            </>
                                                                        }
                                                                    </td> */}
                                                                    <td className="cell_size">
                                                                        <p>{item.created_at.date} </p>
                                                                        <small className="tx-12 tx-gray-500"> {item.created_at.time}</small>
                                                                    </td>
                                                                    <td>
                                                                        {this.state.selectedUserId === item.user_id &&
                                                                            <>
                                                                                {/* <Link
                                                                                    to={
                                                                                        {
                                                                                            pathname: `/users/profile/${item.username}`,
                                                                                            state: { username: item.username }
                                                                                        }
                                                                                    }
                                                                                    className="btn ripple btn-secondary btn-sm see-all-btn mr-2"
                                                                                    data-toggle="tooltip" data-original-title="View"
                                                                                    title="View Profile"
                                                                                >
                                                                                    <i className="icon-eye menu-icon" />
                                                                                </Link> */}

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn ripple btn-purple btn-sm see-all-btn"
                                                                                    onClick={() => this.editRecord(item)}
                                                                                >
                                                                                    <i className="fas fa-edit menu-icon"></i>
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>

                                                {this.state.metaData && this.state.pagination}

                                                <div className="pagination-center">
                                                    {!usersList && <ActivityLoader />}
                                                    {usersList === "404" && <NoContent />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }

}

export default FieldUsers;




// listAllUsers = async () => {
//     if (this.state.filter) {
//         const filter = this.state.filter;
//         var server_response = await ajaxUser.filterUsers(
//             filter.dateFrom,
//             filter.dateTo,
//             // filter.selectedValue,
//             filter.keyword,
//             this.state.currentPage,
//             this.state.status
//         );


//     } else {
//         var server_response = await ajaxUser.listUsers(this.state.status, this.state.currentPage);
//     }

//     if (server_response.status === "OK") {
//         console.log("ok here")
//         this.setState({
//             userList: server_response.details.content.list,
//             metaData: server_response.details.content.meta
//         }, () => this.paginate())

//     } else {
//         this.setState({
//             userList: "404",
//             metaData: false
//         })
//     }
// }


// onClickPage = (page) => {
//     this.setState({
//         currentPage: page,
//         userList: false
//     }, () => this.listUsers())
// }

// onClickNext = () => {
//     //increment page numbers
//     const metaData = this.state.metaData;
//     if (metaData.current_page * 1 + 1 <= metaData.total_pages * 1) {
//         this.setState({
//             currentPage: this.state.currentPage + 1,
//             userList: false

//         }, () => {
//             this.listUsers();
//         })
//     }
// }

// onClickPrevious = () => {
//     const metaData = this.state.metaData;
//     if (metaData.current_page * 1 > 1) {
//         this.setState({
//             currentPage: this.state.currentPage - 1,
//             userList: false
//         }, () => {
//             this.listUsers();
//         })
//     }
// }

// onFilter = async (filter) => {
//     this.setState({
//         filter: false,
//         userList: false,
//         metaData: false
//     }, () => {
//         this.setState({ filter: filter }, () => this.listAllUsers())
//     })
// }

// resetFilter = () => {
//     this.setState({
//         filter: false,
//         userList: false
//     }, () => this.listAllUsers())
// }


// onChangeStatus = (status) => {
//     this.setState({
//         status: status,
//         userList: false,
//         metaData: false,
//         currentPage: 1
//     }, () => this.listAllUsers())
// }

// paginate = () => {
//     if (this.state.metaData) {
//         this.setState({
//             pagination: false
//         }, () => {

//             this.setState({
//                 pagination: <PageNav
//                     totalPages={this.state.metaData.total_pages}
//                     pageList={this.state.metaData.list_of_pages}
//                     currentPage={this.state.currentPage}
//                     onClickNext={this.onClickNext}
//                     onClickPage={this.onClickPage}
//                     onClickPrevious={this.onClickPrevious}
//                 />
//             })

//         })

//     }
// }
