import React from 'react';
import Select from 'react-select';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    //borderBottom: '2px dotted green',
      color: state.isSelected ? '#FFFFFF' : 'black',
    //backgroundColor: state.isSelected ? '#1c1c38' : 'white',
   // color: '#000000',
    textAlign:'left',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor:'#FFFFFF',
    color: '#000000',
   // marginTop: "5%",
  }),
  singleValue:(provided)=>({
    ...provided,
    color: '#000000'
  })
}


class SelectSearch extends React.Component {
  

  render(){
    
      return ( 
        <>
        {/* <div className="row row-xs align-items mg-b-20">
             <div className="col-md-3 text-left">
                <label className="mg-b-0">{this.props.label}</label>
            </div>
            <div className={this.props.divClass || " col-md-9 mg-t-0"}>
                <Select 
                    options={this.props.options}  
                    className={this.props.className || " form-input"}
                    onChange={this.props.onChange()} 
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    styles = { customStyles }
                />
            </div>
        </div> */}

        <div className="mb-3 w-100">
            <label htmlFor={this.props.htmlFor}>{this.props.label}</label>
            
            <div className={this.props.divClass || ""}>
                <Select 
                    options={this.props.options}  
                    className={this.props.className || " form-input"}
                    onChange={this.props.onChange()} 
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    styles = { customStyles }
                />
            </div>
        </div>
        </>
      );
    
 
  }
}

export default SelectSearch;
