import React from 'react'

import dictionary from '../../utils/dictionary';
import ajaxUser from '../../utils/remote/ajaxUser';
import functions from '../../utils/functions';

// import Cookies from 'universal-cookie';
import AlertError from '../../shared/AlertError';

import logo from '../../assets/logo-normal.svg';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../components/common/ActivityLoader';
// import ActivitySpinner from '../../components/common/ActivitySpinner';

class Login extends React.Component {

    state = {
        username: '',
        password: '',

        error: false,
        info: '',
        loading: false,
        login: false,
    }


    // componentDidMount() {
    //     this.timerLogin = setTimeout(this.funcLogin = async () => {
    //       await this.onPressLogin();
    //       this.timerLogin = setTimeout(this.funcLogin, dictionary._waitTime); // (*)
    //     }, dictionary._waitTime);

    // }

    // componentWillUnmount() {
    //     clearTimeout(this.timerLogin)
    // }

    //show password when when u click checkbox
    showPassword = () => {
        let input = document.getElementById("password");
        if (input.type === "password") {
            input.type = "text";
        } else {
            input.type = "password";
        }
    }

    onPressLogin = async (event) => {

        this.setState({
            // info: <ActivityLoader />
            info: <ActivityLoader />
        })

        event.preventDefault();
        const { username, password } = this.state;

        if (
            username.length > 0 &&
            password.length > 0
        ) {
            //fetch login from the API
            const server_response = await ajaxUser.loginUser(username, password);

            if (server_response.status === "OK") {

                // this.setState({
                //   login: true
                // }, () => {
                //   setTimeout(() => {
                //     this.setState({
                //       login: false
                //     });
                //   }, 3000);
                // });

                localStorage.setItem('payslip@user', server_response.details.content.access_token);

                // decode the token to access some variables for use
                const access_token = localStorage.getItem('payslip@user');
                const decorded_token = functions.parseJwt(access_token);

                // const role_id = decorded_token['data']['role_id'];
                const is_secure = decorded_token['data']['is_secure'];

                if (is_secure === "0") {
                    this.props.history.push('/password-reset');
                    window.location.reload();
                } else {
                    this.props.history.push('/');
                    window.location.reload();
                }
            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }
        } else {
            this.setState({
                info: <AlertError message={dictionary._enterUsername} />
            })
        }
    }

    onChangeUsername = (event) => {
        this.setState({ username: event.target.value });
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }



    render() {
        // const login = this.state.login

        return (
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                        <div className="row flex-grow">
                            <div className="col-lg-6 d-flex align-items-center justify-content-center">
                                <div className="auth-form-transparent text-center p-3">
                                    <div className="brand-logo">
                                        <img src={logo} alt="logo" />
                                    </div>
                                    <h4>Sign into the account!</h4>

                                    {/* <h6 className="font-weight-light">Happy to see you again!</h6> */}
                                    <form className="pt-4" method="post" onSubmit={this.onPressLogin}>
                                        
                                        <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>
                                        
                                        <div className="form-group pt-2">
                                            {/* <label for="exampleInputEmail">Username</label> */}
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-account-outline text-primary"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-lg border-left-0"
                                                    id="exampleInputEmail"
                                                    placeholder="Username"
                                                    onChange={this.onChangeUsername}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label for="exampleInputPassword">Password</label> */}
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-lock-outline text-primary"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    type="password"
                                                    className="form-control form-control-lg border-left-0"
                                                    id="password"
                                                    placeholder="Password"
                                                    onChange={this.onChangePassword}
                                                />
                                            </div>
                                        </div>
                                        <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input type="checkbox" className="form-check-input" onClick={this.showPassword} />
                                                    Show Password
                                                </label>
                                            </div>
                                            <Link to="#" className="auth-link text-black">Forgot password?</Link>
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-block btn-info btn-lg font-weight-medium auth-form-btn">LOGIN</button>
                                        </div>
                                        {/* #ff5811 */}
                                        {/* <div className="mb-2 d-flex">
                                            <button type="button" className="btn btn-facebook auth-form-btn flex-grow mr-1">
                                                <i className="mdi mdi-facebook mr-2"></i>
                                                Facebook
                                            </button>
                                            <button type="button" className="btn btn-google auth-form-btn flex-grow ml-1">
                                                <i className="mdi mdi-google mr-2"></i>
                                                Google
                                            </button>
                                        </div> */}
                                        {/* <div className="text-center mt-4 font-weight-light">
                                            Don't have an account? <a href="register-2.html" className="text-primary">Create</a>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 login-half-bg d-flex flex-row">
                                <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2020  All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default Login;
