import React from 'react';
import logo from '../../assets/logo-white.svg';
import logo_mini from '../../assets/logo-mini.svg';
import { Link } from 'react-router-dom';
import TableTools from '../../components/common/TableTools';

class NavBar extends React.Component {

    onLogout = () => {
        localStorage.removeItem('payslip@user');
        window.location.replace('/');
    }

    onClickAdd = () => {
        this.props.onClickAdd()
    }


    render() {
        return (
            <>
                <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                        <a className="navbar-brand brand-logo navlogo" href="index.html"><img src={logo} alt="logo" /></a>
                        <a className="navbar-brand brand-logo-mini" href="index.html"><img src={logo_mini} alt="logo" /></a>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                            <span className="icon-menu"></span>
                        </button>
                        <ul className="navbar-nav navbar-nav-right">

                            <div className="nav-item dropdown d-flex">
                                <TableTools />
                            </div>

                            <li className="nav-item dropdown d-flex mr-3 ">
                                <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
                                    id="notificationDropdown" href="#" data-toggle="dropdown">
                                    {/* <i className="icon-cog"></i> */}
                                    <i className="icon-head"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                                    aria-labelledby="notificationDropdown">
                                    {/* <p className="mb-0 font-weight-normal float-left dropdown-header">Settings</p> */}
                                    <a className="dropdown-item preview-item">
                                        {/* <i className="icon-head"></i>  */}
                                        Profile
                                    </a>
                                    <Link className="dropdown-item preview-item" to="#" onClick={this.onLogout}>
                                        {/* <i className="icon-inbox"></i>  */}
                                        Sign Out
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                            data-toggle="offcanvas">
                            <span className="icon-menu"></span>
                        </button>
                    </div>
                </nav>
            </>
        )
    }
}

export default NavBar;
