import React from 'react'
// import { Link } from 'react-router-dom'
import TableHeader from '../../../shared/TableHeader'


class BarChart extends React.Component {

    render() {
        return (

            <div className="card">
                <div className="card-body">

                    <div className="row d-flex mb-5 justify-content-between align-items-center">
                        <div className="col-lg-5">
                            <TableHeader
                                title="Website Audience Metrics"
                                subtitle="Chart information comparing the intake of cash as to the bookings"
                            />
                        </div>
                        <div className="col-lg-5">
                            <div className="chart-legends d-lg-block d-none" id="chart-legends"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <canvas id="web-audience-metrics-satacked" className=""></canvas>
                        </div>
                    </div>

                </div>
            </div>

        )
    }

}

export default BarChart
