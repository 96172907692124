import React from 'react';
import ActivityLoader from '../common/ActivityLoader';
import CompanyHeader from '../common/CompanyHeader';
import functions from '../../utils/functions';

class PopRecord extends React.Component {

    state = {
        record: this.props.record
    }

    print = () => {
		functions.printDoc('receipt_doc');
	}

    render() {

        const record = this.state.record;

        return (
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered d-flex  justify-content-center align-items-center" role="document">
                    <div class="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title"></h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                {record && record !== "404" &&
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card custom-card">
                                            <div className="card-body" id="receipt_doc">
                                                <div className="d-lg-flex">
                                                    <h2 className="card-title mb-1">Payslip</h2>
                                                    <div className="ml-auto">
                                                        <p className="mb-1">
                                                            <span className="font-weight-bold">Receipt Date: </span> {record.created_at.short_date}
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr className="mb-4" />

                                                <div className="d-flex justify-content-center align-items-center text-center my-3">
                                                    <div className="col-lg-12">
                                                        <CompanyHeader />
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center align-items-center text-center my-3">
                                                    <div className="col-lg-12">
                                                        <h5>{record.created_for} Payslip</h5>
                                                    </div>
                                                </div>

                                                <div className="table-responsive">
                                                    <table className="table table-invoice table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td className="font-weight-bold" colSpan={2}>Name</td>
                                                                <td colSpan={3}>{record.user.full_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold" colSpan={2}>Identification</td>
                                                                <td colSpan={3}>{record.user.username}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="table-responsive mg-t-40">
                                                    <table className="table table-invoice table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center font-weight-bold" colSpan={5}>Days Worked</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">Week 1</td>
                                                                <td className="text-center">Week 2</td>
                                                                <td className="text-center">Week 3</td>
                                                                <td className="text-center">Week 4</td>
                                                                <td className="text-center">Week 5</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">{record.week1}</td>
                                                                <td className="text-center">{record.week2}</td>
                                                                <td className="text-center">{record.week3}</td>
                                                                <td className="text-center">{record.week4}</td>
                                                                <td className="text-center">{record.week5}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center font-weight-bold" colSpan={2}>Total Days Worked</td>
                                                                <td className="text-center" colSpan={3}>{record.total_days}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center font-weight-bold" colSpan={2}>Amount Per Day</td>
                                                                <td className="text-center" colSpan={3}>{record.amount_per_day}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="table-responsive mg-t-40">
                                                    <table className="table table-invoice table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center font-weight-bold">Gross Pay</td>
                                                                <td className="text-center font-weight-bold">{record.gross_pay}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="table-responsive mg-t-40">
                                                    <table className="table table-invoice table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center font-weight-bold" colSpan={5}>Statutory Deduction</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>PAYE</td>
                                                                <td colSpan={3}>{record.paye_deduction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>NSSF - 5%</td>
                                                                <td colSpan={3}>{record.nssf_deduction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Local Service Tax</td>
                                                                <td colSpan={3}>{record.lst_deduction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Advancement</td>
                                                                <td colSpan={3}>{record.adv_deduction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Union Tax</td>
                                                                <td colSpan={3}>{record.union_fee_deduction}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center font-weight-bold" colSpan={2}>Total Deductions</td>
                                                                <td className="text-center" colSpan={3}>{record.total_deductions}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="table-responsive mg-t-40">
                                                    <table className="table table-invoice table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center font-weight-bold">Net Pay</td>
                                                                <td className="text-center font-weight-bold">{record.net_pay}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                            <div className="card-footer text-right">

                                                {/* <button type="button" className="btn ripple btn-secondary mb-1"><i className="fe fe-send mr-1"></i> Send Invoice</button> */}
                                                <button
                                                    type="button"
                                                    onClick={this.print}
                                                    className="btn ripple btn-info mb-1"><i className="fe fe-printer mr-1"></i> Print</button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {!record && <ActivityLoader />}
                                <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>
                            </div>





                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopRecord;
