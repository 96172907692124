import React, { Component, useEffect } from 'react';
// import { AuthConsumer, AuthProvider } from './AuthContext';

import { ThemeProvider } from './ThemeContext';



const SuperProvider = (props) => {

    return (

        <ThemeProvider>
            {props.children}

        </ThemeProvider>
    )
        // <AuthProvider>
        //     {/* <AuthConsumer> */}
        //         {authProps => {
        //             if (authProps.permissionList) {
        //                 return (
        //                     <ThemeProvider auth={authProps}>
        //                         {props.children}

        //                     </ThemeProvider>
        //                 )
        //             } else {
        //                 return props.children
        //             }
        //         }}
        //     {/* </AuthConsumer> */}

        // </AuthProvider >

    // )
}

export default SuperProvider;