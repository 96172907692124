import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('payslip@user');

const apiHost = dictionary.apiHost

export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password
        }

        // console.log(data)

        try {
            let response = await fetch(apiHost + 'user/login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async resetPassword(password) {

        let data = {
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'user/auth/password.reset',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async userInfo(user_id) {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'user/profile',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async deactivateUser(user_id) {

        let data = {
            // "role_id": role_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user.deactivate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async getUserByUsername(username) {

        let data = {
            // "user_id": user_id,
            "username": username
        }

        try {
            let response = await fetch(apiHost + 'user/search/username',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterUsers(
        createdFrom,
        createdTo,
        // startedBy,
        keyword,
        page,
        status
    ) {

        let data = {
            // "conductor_id": startedBy,
            "created_from": createdFrom,
            "created_to": createdTo,
            "keyword": keyword,
            "page": page,
            "status": status
        }
        try {
            let response = await fetch(apiHost + 'user/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listRecentUsers(status, page) {

        let data = {
            "source": "admin"
        }


        try {
            let response = await fetch(apiHost + 'user/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listUsers(status, page) {

        let data = {
            "status": status,
            "page": page
        }

        try {
            let response = await fetch(apiHost + 'user/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    

    async listSelectionUsers() {

        // let data = {
        //     "status": status,
        //     "page": page
        // }

        try {
            let response = await fetch(apiHost + 'user/list/selection',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listSelectionSystemUsers() {

        let data = {
            "roles_codes": [
                "SUPER",
                "ADMIN",
                "FINANCE",
                "ITRESOURCE",
                "BRANCHMAN",
                "HUMAN"
            ]
        }

        try {
            let response = await fetch(apiHost + 'user/list/criteria.rolecode',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listSelectionFieldUsers() {

        let data = {
            "roles_codes": [
                "CONDUCTOR",
                "COBRA",
                "ZULU",
                "SUPERVISOR",
                "CASHIER",
                "VENDOR"
            ]
        }

        try {
            let response = await fetch(apiHost + 'user/list/criteria.rolecode',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countUsers() {

        // let data = {
        //     "user_id": user_id
        // }

        try {
            let response = await fetch(apiHost + 'user.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createUser(
        firstName,
        lastName,
        userName,
        email,
        profilePhoto,
        phoneNumber,
        roleId,
        amountPerDay,
        dateOfBirth,
        isUnionSubscribed,
        tinNumber,
        createdBy
    ) {
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "username": userName,
            "email": email,
            "profile_photo": profilePhoto,
            "phone_number": phoneNumber,
            "role_id": roleId,
            "amount_per_day": amountPerDay,
            "date_of_birth": dateOfBirth,
            "is_union_subscribed": isUnionSubscribed,
            "tin_number": tinNumber,
            "created_by": createdBy
        }
        try {
            let response = await fetch(apiHost + 'user.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });


            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async updateUser(
        userId,
        firstName,
        lastName,
        userName,
        phoneNumber,
        roleId,
        branchId,
        email,
        amountPerDay,
        isUnionSubscribed,
        dateOfBirth,
        tinNumber,
        updatedBy,
    ) {

        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "username": userName,
            "email": email,
            "phone_number": phoneNumber,
            "role_id": roleId,
            "branch_id": branchId,
            "amount_per_day": amountPerDay,
            "is_union_subscribed": isUnionSubscribed,
            "date_of_birth": dateOfBirth,
            "tin_number": tinNumber,
            "updated_by": updatedBy,
            "user_id": userId
        }
        try {
            let response = await fetch(apiHost + 'user.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async importUsers(file) {

        let data = {
            "file": file
        }

        try {
            let response = await fetch(apiHost + 'user/import',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async requestPasswordReset(username) {
        let data = {
          username: username
        }
        try {
          let response = await fetch(apiHost + 'user/auth/password/reset.request',
            {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + ACCESS_TOKEN,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data),
            });
          let result = await response.json();
          return result;
        } catch (error) {
          let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
          return connError;
        }
      }

}
