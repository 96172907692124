import React from 'react'
import AlertError from '../../shared/AlertError';
import dictionary from '../../utils/dictionary';
import ajaxUser from '../../utils/remote/ajaxUser';

import logo from '../../assets/logo-normal.svg'


class PasswordReset extends React.Component {

    state = {
        password: '',
        confirmPassword: '',
        editedPasswordUserId: '',
        userRecord: false,
        error: false,
        info: '',
        loading: false
    }

    componentDidMount() {
        // if (!this.props.location.state) {
        //     this.props.history.push('/users');
        //   } else {
        //     this.setState({
        //       userRecord:this.props.location.state.record
        //     }, ()=>{

        //       this.setState({
        //         editedPasswordUserId:this.state.userRecord.user_id
        //       })
        //     })
        //   }
    }

    // handleValidatePassword = () => {
    //     const { password, confirmPassword } = this.state;
    //     // perform all neccassary validations
    //     if (password !== confirmPassword) {
    //         alert("Passwords don't match");
    //         this.setState({
    //             info: <AlertError message={dictionary._enterMatchingPasswords} />,
    //             loading: ''
    //         })
    //     } else {
    //         // make API call
    //     }
    // }

    onPressReset = async (event) => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;

        if (
            password.length > 0 &&
            confirmPassword.length > 0
        ) {

            if (password !== confirmPassword) {
                this.setState({
                    info: <AlertError message={dictionary._enterMatchingPasswords} />,
                    loading: ''
                })
            } else {
                const server_response = await ajaxUser.resetPassword(password);
                if (server_response.status === "OK") {
                    localStorage.removeItem('payslip@user');
                    window.location.replace('/login');

                } else {

                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    })

                }
            }
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />,
                loading: ''
            })
        }


    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    onConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value });
    }

    //show password when when u click checkbox
    showPassword = () => {
        let input = document.getElementById("password");
        let input2 = document.getElementById("password2");
        if (
            input.type === "password" &&
            input2.type === "password"
        ) {
            input.type = "text";
            input2.type = "text";
        } else {
            input.type = "password";
            input2.type = "password"
        }
    }



    render() {


        return (

            <div className="container-scroller">
                {/* <div className="card login-card">
                        <div className="card-body"> */}
                <div className="container-fluid page-body-wrapper full-page-wrapper">

                    <div className="content-wrapper d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left py-5 px-4 px-sm-5">

                                    <div className="text-center mb-4 mt-2 brand-logo text-center mb-4 mt-2">
                                        <img src={logo} className="header-brand-img" alt="logo" />
                                        {/* <img src={logo} className="header-brand-img theme-logos" alt="logo" /> */}
                                    </div>

                                    <h4 className='my-4 text-center'>Reset password here!</h4>
                                    {/* <h6 className="font-weight-light">Sign in to continue.</h6> */}

                                    {this.state.info}

                                    <form method="post" onSubmit={this.onPressReset} className="pt-3">
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                name="password"
                                                id="password"
                                                placeholder="New Password"
                                                onChange={this.onChangePassword}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                name="password"
                                                id="password2"
                                                placeholder="Confirm New Password"
                                                onChange={this.onConfirmPassword}
                                            />
                                        </div>

                                        <div className="form-options-wrapper my-3">
                                            <div className="custom-control custom-checkbox login-card-check-box d-flex justify-content-between align-items-center">
                                                <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={this.showPassword} />
                                                <label className="custom-control-label" htmlFor="customCheck1">Show password</label>
                                            </div>
                                        </div>

                                        {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input type="checkbox" className="form-check-input" onClick={this.showPassword} />
                                                    Show Password
                                                </label>
                                            </div>
                                        </div> */}

                                        <div className="mt-3">
                                            <button className="btn btn-block btn-info btn-lg font-weight-medium auth-form-btn">Reset Password</button>
                                        </div>

                                        {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customCheck1"
                                                        onClick={this.showPassword}
                                                    />
                                                    Show passwords
                                                </label>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>
                    </div> */}
            </div>

        );

    }
}

export default PasswordReset;
