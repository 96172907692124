import React from 'react'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

class TableHeader extends React.Component {

    render() {
        return (
            <div className="table-header">
                <div>
                    <h5 className="card-title mb-1">{this.props.title}</h5>
                    <p className="text-muted card-description m-0">{this.props.subtitle}</p>
                </div>

                {this.props.view && 
                    <Link 
                        type="button" 
                        className="btn ripple btn-purple btn-sm see-all-btn" 
                        to={this.props.link} 
                        onClick={this.props.onClick}
                    >
                        {this.props.view}
                    </Link>
                }

                {/* {this.props.options &&
                    <>
                        <a className="bt btn-sm ripple btn-light dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i className="fas fa-ellipsis-v mr-1"></i>
                        </a>
                        <div className="dropdown-menu tx-13">
                            {this.props.options}
                        </div>
                    </>
                } */}


                {/* <div>
                    <h5 class="card-title mb-2">Stacked Bar Chart</h5>
                    <p class="text-muted card-sub-title card-description">Below is the basic Stacked Bar chart example.</p>
                </div> */}
            </div>

        )
    }

}

export default TableHeader
