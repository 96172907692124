import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('payslip@user');
const apiHost = dictionary.apiHost

export default {

    async countPayslips() {

        // let data = {
        //     "user_id": user_id
        // }

        try {
            let response = await fetch(apiHost + 'payslip.count',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async countSumPayslips() {

        // let data = {
        //     "created_for": createdFor
        // }

        try {
            let response = await fetch(apiHost + 'payslip.total',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async filterPayslips(
        createdFrom,
        createdTo,
        // startedBy,
        keyword,
        page,
        status
    ) {

        let data = {
            // "conductor_id": startedBy,
            "created_from": createdFrom,
            "created_to": createdTo,
            "keyword": keyword,
            "page": page,
            "status": status
        }
        try {
            let response = await fetch(apiHost + 'payslip/filter',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listRecentPayslips(status, page) {

        let data = {
            "source": "admin"
        }


        try {
            let response = await fetch(apiHost + 'payslip/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listPayslips(status, page) {

        let data = {
            "status": status,
            "page": page
        }


        try {
            let response = await fetch(apiHost + 'payslip/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async listSelectionPayslips() {

        // let data = {
        //     "status": status,
        //     "page": page
        // }

        try {
            let response = await fetch(apiHost + 'payslip/list/selection',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async createPayslip(
        payslipUser,
        week1,
        week2,
        week3,
        week4,
        week5,
        // payslipAmountPerDay,
        payslipAdvanceDeduction,
        // payslipUnionFeeDeduction,
        created_for,
    ) {
        let data = {
            "user_id": payslipUser,
            "week1": week1,
            "week2": week2,
            "week3": week3,
            "week4": week4,
            "week5": week5,
            // "amount_per_day": payslipAmountPerDay,
            "adv_deduction": payslipAdvanceDeduction,
            // "union_fee_deduction": payslipUnionFeeDeduction
            "created_for": created_for,
        }

        try {
            let response = await fetch(apiHost + 'payslip/create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });


            let result = await response.json();
            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async updatePayslip(
        payslipID,
        week1,
        week2,
        week3,
        week4,
        week5,
        // payslipAmountPerDay,
        payslipAdvanceDeduction,
        // payslipUnionFeeDeduction,
        createdFor,
        updatedBy,
    ) {

        let data = {
            "payslip_id": payslipID,
            "week1": week1,
            "week2": week2,
            "week3": week3,
            "week4": week4,
            "week5": week5,
            // "amount_per_day": payslipAmountPerDay,
            "adv_deduction": payslipAdvanceDeduction,
            // "union_fee_deduction": payslipUnionFeeDeduction
            "created_for": createdFor,
            "updated_by": updatedBy,
        }
        try {
            let response = await fetch(apiHost + 'payslip.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },


    async importPayslips(file) {

        let data = {
            "file": file
        }

        try {
            let response = await fetch(apiHost + 'payslip/import',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();


            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

}
