import React from 'react';
import NavBar from '../shared/navbar/NavBar';
import Footer from '../shared/footer/Footer';
import SideBar from '../shared/sidebar/SideBar';
import Title from '../shared/Title';
import DashCard from '../components/dashboard/cards/DashCard';
import BarChart from '../components/dashboard/charts/BarChart';
import RecentActivity from '../components/dashboard/RecentActivity';
import RecentPayslips from '../components/dashboard/RecentPayslips';
import Ratings from '../components/dashboard/Rating';
import ajaxPayslips from '../utils/remote/ajaxPayslips';
import dictionary from '../utils/dictionary';
import functions from '../utils/functions';
import ajaxUser from '../utils/remote/ajaxUser';
import RecentUsers from '../components/dashboard/RecentUsers';

// import Banner from '../../components/Home/banner/banner';
// import Header from '../../shared/header/Header';
// import Description from '../../components/Home/description/description';
// import Footer from '../../shared/footer/Footer';
// import Cards from '../../components/Home/cards/cards';
// import Testimony from '../../components/Home/testimonials/testimonials';
// import Testimonial from '../../components/Home/testimonials/Testimonial';
// import Contacts from '../../shared/contact/contact';

class Operations extends React.Component {

  state = {
    userId: functions.sessionGuard(),
    createdFor: 'December',
    countPayslips: '...',
    countUsers: '...',
    countSumPayslips: '...',
  }

  componentDidMount() {

    this.timerCountUsers = setTimeout(this.funcUserCount = async () => {
      await this.countUsers();
      this.timerCountUsers = setTimeout(this.funcUserCount, dictionary._waitTime); // (*)
    }, dictionary._waitTime)

    this.timerCountPayslips = setTimeout(this.funcPayslipCount = async () => {
      await this.countPayslips();
      this.timerCountPayslips = setTimeout(this.funcPayslipCount, dictionary._waitTime); // (*)
    }, dictionary._waitTime)

    this.timerCountSumPayslips = setTimeout(this.funcPayslipSumCount = async () => {
      await this.totalSumPayslips();
      this.timerCountSumPayslips = setTimeout(this.funcPayslipSumCount, dictionary._waitTime); // (*)
    }, dictionary._waitTime)

  }

  componentWillUnmount() {
    clearTimeout(this.timerCountPayslips)
    clearTimeout(this.timerCountUsers)
    clearTimeout(this.timerCountSumPayslips)
  }


  countPayslips = async () => {
    const server_response = await ajaxPayslips.countPayslips(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        countPayslips: server_response.details.content.total_c
      })
    } else {
      this.setState({
        countPayslips: "..."
      })
    }
  }

  countUsers = async () => {
    const server_response = await ajaxUser.countUsers(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        countUsers: server_response.details.content.total_c,
      })
    } else {
      this.setState({
        countUsers: "...",
      })
    }
  }


  totalSumPayslips = async () => {
    const server_response = await ajaxPayslips.countSumPayslips(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        countSumPayslips: server_response.details.content.total_c,
      })
    } else {
      this.setState({
        countSumPayslips: "...",
      })
    }
  }



  render() {

    return (

      <div class="container-scroller">
        <NavBar />

        <div class="container-fluid page-body-wrapper">
          <SideBar />

          <div class="main-panel">
            <div class="content-wrapper">
              <Title
                title="Hi, welcome back!"
                description="JANUARY 20, 2022"
                // title="All System Roles"
                previousPageTitle="Dashboard"
              // back="/"
              />

              <div class="row mt-3">
                <div class="col-xl-3 p-0">
                  <div class="d-flex justify-content-between flex-column grid-margin stretch-card">

                    <DashCard
                      name="Total Users"
                      icon="fas fa-user"
                      color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                      percentage="Total Number"
                      value={this.state.countUsers}
                      href="/users"
                    />

                    <DashCard
                      name="Total Payslips"
                      icon="fas fa-book"
                      color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                      percentage="Generated payslips "
                      value={this.state.countPayslips}
                      href="/payslips"
                    />

                    <DashCard
                      name="Total Payout"
                      icon="fas fa-box-open"
                      color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                      percentage="Previous month"
                      value={this.state.countSumPayslips}
                      href="/finance"
                    />

                  </div>
                </div>

                <div className="col-xl-9 d-flex grid-margin stretch-card">
                  <RecentPayslips />
                </div>

                {/* <div className="col-xl-3 d-flex grid-margin stretch-card">
                  <RecentActivity />
                </div> */}

              </div>

              <div class="row mt-3">
                
                <div className="col-xl-9 d-flex grid-margin stretch-card">
                  <RecentUsers />
                </div>

                <div class="col-xl-3 p-0">
                  <RecentActivity />
                </div>

              </div>

            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }

}

export default Operations;
