import React from 'react'

import functions from '../../utils/functions'

import ajaxPayslips from '../../utils/remote/ajaxPayslips'

import ActivitySpinner from '../../components/common/ActivitySpinner'
import TextInput from '../../components/common/TextInput'
import dictionary from '../../utils/dictionary'
import AlertSuccess from '../../shared/AlertSuccess'
import AlertError from '../../shared/AlertError'
import SelectSearch from '../../shared/SelectSearch'

import NavBar from '../../shared/navbar/NavBar'
import SideBar from '../../shared/sidebar/SideBar'
import Title from '../../shared/Title'
import Footer from '../../shared/footer/Footer'
import DashCard from '../../components/dashboard/cards/DashCard'
import RecentActivity from '../../components/dashboard/RecentActivity'
import ajaxUser from '../../utils/remote/ajaxUser'
import ActivityLoader from '../../components/common/ActivityLoader'


class CreatePayslip extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        userList: '',
        userRecord: false,
        payslipName: '',
        payslipDescription: '',
        payslipPhoto: '',
        fileName: '',
        info: '',

        payslipUser: '',
        week1: '',
        week2: '',
        week3: '',
        week4: '',
        week5: '',
        payslipAdvanceDeduction: '',
        payslipAmountPerDay: '',
        payslipUnionFeeDeduction: '',
        payslipMonth: '',

        countPayslips: '...',
    }

    componentDidMount() {
        this.timerCountPayslips = setTimeout(this.funcPayslipCount = async () => {
            await this.countPayslips();
            this.timerCountPayslips = setTimeout(this.funcPayslipCount, dictionary._waitTime); // (*)
        }, dictionary._waitTime)

        this.listUsers();
    }

    componentWillUnmount() {
        clearTimeout(this.timerCountPayslips)
    }

    onChangeWeek1 = (event) => {
        this.setState({ week1: event.target.value })
    }

    onChangeWeek2 = (event) => {
        this.setState({ week2: event.target.value })
    }

    onChangeWeek3 = (event) => {
        this.setState({ week3: event.target.value })
    }

    onChangeWeek4 = (event) => {
        this.setState({ week4: event.target.value })
    }

    onChangeWeek5 = (event) => {
        this.setState({ week5: event.target.value })
    }

    onChangeAdvanceDeduction = (event) => {
        this.setState({ payslipAdvanceDeduction: event.target.value })
    }

    onChangeAmountPerDay = (event) => {
        this.setState({ payslipAmountPerDay: event.target.value })
    }

    onChangeUnionFeeDeduction = (event) => {
        this.setState({ payslipUnionFeeDeduction: event.target.value })
    }

    // onChangePayslipMonth = (event) => {
    //     this.setState({ payslipMonth: event.target.value })
    // }

    onChangePayslipMonth = (selectedValue) => {
        this.setState({
            payslipMonth: selectedValue.value
        })
    }


    countPayslips = async () => {
        const server_response = await ajaxPayslips.countPayslips(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                countPayslips: server_response.details.content.total_c
            })
        } else {
            this.setState({
                countPayslips: "..."
            })
        }
    }

    listUsers = async () => {
        const server_response = await ajaxUser.listSelectionUsers();
        if (server_response.status === "OK") {
            this.setState({
                userList: server_response.details.content.list
            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }

    onChangeStaff = (selectedOption) => {
        this.setState({
            payslipUser: selectedOption.value,
            // payslipAmountPerDay: selectedOption.amount
        })
    }

    onSubmit = async (event) => {

        this.setState({
            info: <ActivityLoader />,
            createPayslip: false
        })

        event.preventDefault();
        const {
            payslipUser,
            week1,
            week2,
            week3,
            week4,
            week5,
            // payslipAmountPerDay,
            payslipAdvanceDeduction,
            // payslipUnionFeeDeduction
            payslipMonth,
        } = this.state;

        if (
            payslipUser.length > 0
            // payslipAmountPerDay.length > 0
        ) {
            //fetch login from the API
            const server_response = await ajaxPayslips.createPayslip(
                payslipUser,
                week1,
                week2,
                week3,
                week4,
                week5,
                // payslipAmountPerDay,
                payslipAdvanceDeduction,
                // payslipUnionFeeDeduction
                payslipMonth,
            );

            if (server_response.status === "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                }, () =>
                    this.setState({
                        payslipUser: '',
                        week1: '',
                        week2: '',
                        week3: '',
                        week4: '',
                        week5: '',
                        // payslipAmountPerDay: '',
                        payslipAdvanceDeduction: '',
                        // payslipUnionFeeDeduction: '',
                    })
                )

            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })
            }

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />,
                loading: ''
            })
        }


    }

    onClickExit = () => {
        this.props.history.push({
            pathname: '/payslips'
        })
    }


    render() {

        const userList = this.state.userList;
        const optionsStaff = [];
        if (userList && userList !== "404") {
            userList.map((item, key) =>
                optionsStaff.push({
                    label: item.full_name,
                    value: item.user_id,
                })
            )
        }

        const selectMonths = [
            { value: 'January', label: 'January' },
            { value: 'Feburary', label: 'Feburary' },
            { value: 'March', label: 'March' },
            { value: 'April', label: 'April' },
            { value: 'May', label: 'May' },
            { value: 'June', label: 'June' },
            { value: 'July', label: 'July' },
            { value: 'August', label: 'August' },
            { value: 'September', label: 'September' },
            { value: 'October', label: 'October' },
            { value: 'November', label: 'November' },
            { value: 'December', label: 'December' },
        ]

        // console.log(this.state.payslipUser + ' ' + this.state.payslipAmountPerDay)

        return (
            <div className="container-scroller">
                <NavBar />

                <div className="container-fluid page-body-wrapper">
                    <SideBar />

                    <div className="main-panel">
                        <div className="content-wrapper">

                            <Title
                                title="Create Payslip"
                                description="JANUARY 20, 2022"
                                previousPageTitle="All System Payslips"
                                back="/payslips"
                            />

                            <div className="row mt-3">
                                <div className="col-xl-9 grid-margin-lg-0 stretch-card">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>

                                            <form method="post" onSubmit={this.onSubmit} className="forms-sample">

                                                <SelectSearch
                                                    label="Staff member"
                                                    onChange={() => this.onChangeStaff}
                                                    options={optionsStaff}
                                                    placeholder={"Select Staff..."}
                                                />

                                                <br />

                                                <div className='d-flex justify-content-around'>
                                                    <TextInput
                                                        label="Week1"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangeWeek1}
                                                        value={this.state.week1}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Week2"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangeWeek2}
                                                        value={this.state.week2}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Week3"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangeWeek3}
                                                        value={this.state.week3}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Week4"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangeWeek4}
                                                        value={this.state.week4}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Week5"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangeWeek5}
                                                        value={this.state.week5}
                                                    />
                                                </div>

                                                {/* <TextInput
                                                    label="Amount per day"
                                                    type="text"
                                                    htmlFor="exampleInputName1"
                                                    id="exampleInputName1"
                                                    onChange={() => this.onChangeAmountPerDay}
                                                    value={this.state.payslipAmountPerDay}
                                                /> */}

                                                <SelectSearch
                                                    label="Month Of Payment"
                                                    onChange={() => this.onChangePayslipMonth}
                                                    options={selectMonths}
                                                    placeholder={"Month Of Payment"}
                                                />

                                                <br />

                                                {/* <div className='d-flex justify-content-around'> */}
                                                
                                                <TextInput
                                                    label="Advanced Deduction"
                                                    type="text"
                                                    htmlFor="exampleInputName1"
                                                    id="exampleInputName1"
                                                    onChange={() => this.onChangeAdvanceDeduction}
                                                    value={this.state.payslipAdvanceDeduction}
                                                />

                                                {/* &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp; */}


                                                {/* <TextInput
                                                        label="Month Of Payment"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangePayslipMonth}
                                                        value={this.state.payslipMonth}
                                                    /> */}

                                                {/* </div> */}

                                                <button type="submit" onClick={this.onSubmit} className="btn btn-purple mr-2">Submit</button>
                                                <button className="btn btn-light" onClick={this.onClickExit}>Cancel</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-3 p-0">
                                    <div className="d-flex justify-content-between flex-column">

                                        <DashCard
                                            name="Total Payslips"
                                            icon="fas fa-book"
                                            color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                                            percentage="Generated payslips "
                                            value={this.state.countPayslips}
                                            href="/payslips"
                                        />

                                        <RecentActivity />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        );

    }
}

export default CreatePayslip;
