import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import Login from '../pages/auth/Login';
// import Operations from '../pages/Operations';
// import Payslips from '../pages/Payslips';
import functions from '../utils/functions';
import Operations from '../pages/Operations';
import Payslips from '../pages/Payslips';
import Users from '../pages/Users';
import SuperProvider from '../Context/SuperProvider';
import CreatePayslip from '../pages/payslips/CreatePayslip';
import CreateUser from '../pages/users/CreateUser';
import UpdateUser from '../pages/users/UpdateUser';
import PasswordReset from '../pages/auth/PasswordReset';
import UserProfile from '../pages/users/UserProfile';
import Finance from '../pages/Finance';
import UpdatePayslip from '../pages/payslips/UpdatePayslip';
import SystemUsers from '../pages/management/SystemUsers';
import FieldUsers from '../pages/management/FieldUsers';
import UpdateSysUser from '../pages/users/UpdateSysUser';


class Routes extends React.Component {

    state = {
        screen: "Login",
        loggedIn: false,
        accessInfo: functions.permissionGuard()
    }

    componentDidMount() {
        this.checkLogin();
    }

    checkLogin() {
        if (!localStorage.getItem("payslip@user")) {
            this.setState({
                loggedIn: false
            })
        } else {
            this.setState({
                loggedIn: true
            })
        }
    }

    render() {

        return (
       <SuperProvider>


            <Router>
                <Switch>
                    {this.state.loggedIn === false &&
                        <>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/login" component={Login} />
                        </>
                    }
                    {this.state.loggedIn === true &&
                        <>
                            <Route exact path="/" component={Operations} />

                            {/* Authentication */}
                            <Route exact path="/password-reset" component={PasswordReset} />

                            {/* // Finance */}
                            <Route exact path="/finance" component={Finance} />

                            {/* // Payslips */}
                            <Route exact path="/payslips" component={Payslips} />
                            <Route exact path="/payslips/new" component={CreatePayslip} />
                            <Route exact path="/payslips/update" component={UpdatePayslip} />

                            {/* // Users */}
                            <Route exact path="/users" component={Users} />
                            <Route exact path="/sys-users" component={SystemUsers} />
                            <Route exact path="/fld-users" component={FieldUsers} />
                            <Route exact path="/users/new" component={CreateUser} />
                            <Route exact path="/users/update" component={UpdateUser} />
                            <Route exact path="/users/sys/update" component={UpdateSysUser} />
                            <Route exact path="/users/profile/:user" component={UserProfile} />
                        </>
                    }
                </Switch>
            </Router>
                </SuperProvider>

        )
    }
}


export default Routes;
