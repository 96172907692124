import React from 'react'

import functions from '../../utils/functions'

import ajaxPayslips from '../../utils/remote/ajaxPayslips'

import ActivitySpinner from '../../components/common/ActivitySpinner'
import TextInput from '../../components/common/TextInput'
import dictionary from '../../utils/dictionary'
import AlertSuccess from '../../shared/AlertSuccess'
import AlertError from '../../shared/AlertError'
import SelectSearch from '../../shared/SelectSearch'

import NavBar from '../../shared/navbar/NavBar'
import SideBar from '../../shared/sidebar/SideBar'
import Title from '../../shared/Title'
import Footer from '../../shared/footer/Footer'
import DashCard from '../../components/dashboard/cards/DashCard'
import RecentActivity from '../../components/dashboard/RecentActivity'
import ajaxUser from '../../utils/remote/ajaxUser'
import ajaxRoles from '../../utils/remote/ajaxRoles'
import ActivityLoader from '../../components/common/ActivityLoader'
import ImageUpload from '../../shared/ImageUpload'


class CreateUser extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        roleId: '',
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        phoneNumber: '',

        amountPerDay: '',
        dateOfBirth: '',
        isUnionSubscribed: '',
        tinNumber: '',

        profilePhoto: '',
        fileName: '',
        allRoles: false,
        info: '',

        countUsers: '...',
    }

    componentDidMount() {
        // this.fetchRoles();
        // this.getLoggedInUser();

        // this.countActivePayslips();
        // this.countClampedVehicles();
        // this.countTowedVehicles();
        // this.countAvailableParking();

        // this.timerCountPayslips = setTimeout(this.funcSessionCount = async () => {
        //     await this.countActivePayslips();
        //     this.timerCountPayslips = setTimeout(this.funcSessionCount, dictionary._waitTime); // (*)
        // }, dictionary._waitTime)

        // this.getAllStartedBy();
        // this.setState({
        //     menuOptions: {
        //         "addSession": () => this.onClickStartSession()
        //     }
        // })

        this.fetchRoles();

        this.timerCountUsers = setTimeout(this.funcUserCount = async () => {
            await this.countUsers();
            this.timerCountUsers = setTimeout(this.funcUserCount, dictionary._waitTime); // (*)
        }, dictionary._waitTime)
    }

    componentWillUnmount() {
        clearTimeout(this.timerCountUsers)
    }

    countUsers = async () => {
        const server_response = await ajaxUser.countUsers(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                countUsers: server_response.details.content.total_c,
            })
        } else {
            this.setState({
                countUsers: "...",
            })
        }
    }

    onChangeFirstName = (event) => {
        this.setState({ firstName: event.target.value })
    }

    onChangeLastName = (event) => {
        this.setState({ lastName: event.target.value })
    }

    onChangeUserName = (event) => {
        this.setState({ userName: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onChangePhoneNumber = (event) => {
        this.setState({ phoneNumber: event.target.value })
    }

    onChangeAmountPerDay = (event) => {
        this.setState({ amountPerDay: event.target.value })
    }

    onChangeDateOfBirth = (event) => {
        this.setState({ dateOfBirth: event.target.value })
    }

    onChangeTinNumber = (event) => {
        this.setState({ tinNumber: event.target.value })
    }

    onChangeImage = (event) => {

        this.setState({
            fileName: event.target.value
        })
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.setState({
                profilePhoto: e.target.result
            })
        }
    }

    onChangeRole = (selectedRole) => {
        this.setState({
            roleId: selectedRole.value
        })
    }

    onChangeIsUnionSubscribed = (selectedValue) => {
        this.setState({
            isUnionSubscribed: selectedValue.value
        })
    }

    fetchRoles = async () => {
        const server_response = await ajaxRoles.getRoles(this.state.userId);
        if (server_response.status === "OK") {
            this.setState({
                allRoles: server_response.details.content.list
            })
        } else {
            this.setState({
                allRoles: "404"
            })
        }
    }

    onSubmit = async (event) => {

        this.setState({
            info: <ActivityLoader />,
            createUser: false
        })

        event.preventDefault();
        const {
            firstName,
            lastName,
            userName,
            email,
            profilePhoto,
            phoneNumber,
            roleId,
            amountPerDay,
            dateOfBirth,
            isUnionSubscribed,
            tinNumber,
        } = this.state;

        if (
            firstName.length > 0 ||
            lastName.length > 0 ||
            userName.length > 0 ||
            amountPerDay.length > 0 ||
            // profilePhoto > 0 ||
            phoneNumber.length > 0 ||
            roleId.length > 0
        ) {
            //fetch login from the API
            const server_response = await ajaxUser.createUser(
                firstName,
                lastName,
                userName,
                email,
                profilePhoto,
                phoneNumber,
                roleId,
                amountPerDay,
                dateOfBirth,
                isUnionSubscribed,
                tinNumber,
                this.state.userId
            );

            if (server_response.status === "OK") {
                this.setState({
                    info: <AlertSuccess message={server_response.details.message} />
                })
                this.setState({
                    firstName: '',
                    lastName: '',
                    userName: '',
                    email: '',
                    profilePhoto: '',
                    phoneNumber: '',
                    amountPerDay: '',
                    dateOfBirth: '',
                    isUnionSubscribed: '',
                    tinNumber: ''
                })

            } else {
                this.setState({
                    info: <AlertError message={server_response.details.message} />
                })

            }

        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />,
                loading: ''
            })
        }


    }

    onClickExit = () => {
        this.props.history.push({
            pathname: '/users'
        })
    }



    render() {

        const allRoles = this.state.allRoles;
        const optRoles = [];

        if (allRoles && allRoles !== "404") {
            allRoles.map((item, key) =>
                optRoles.push({
                    label: item.role_name,
                    value: item.role_id
                }))
        }

        const selectOptions = [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' }
        ]

        return (
            <div className="container-scroller">
                <NavBar />

                <div className="container-fluid page-body-wrapper">
                    <SideBar />

                    <div className="main-panel">
                        <div className="content-wrapper">

                            <Title
                                title="Create User"
                                description="JANUARY 20, 2023"
                                previousPageTitle="All System Users"
                                back="/users"
                            />

                            <div className="row mt-3">
                                <div className="col-xl-9 grid-margin-lg-0 stretch-card">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>

                                            <form method="post" onSubmit={this.onSubmit} className="forms-sample">

                                                <div className='d-flex justify-content-between'>
                                                    <TextInput
                                                        label="First Name"
                                                        type="text"
                                                        onChange={() => this.onChangeFirstName}
                                                        value={this.state.firstName}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Last Name"
                                                        type="text"
                                                        onChange={() => this.onChangeLastName}
                                                        value={this.state.lastName}
                                                    />
                                                </div>



                                                <div className='d-flex justify-content-between'>
                                                    <TextInput
                                                        label="Username"
                                                        type="text"
                                                        onChange={() => this.onChangeUserName}
                                                        value={this.state.userName}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Phone Number"
                                                        type="text"
                                                        onChange={() => this.onChangePhoneNumber}
                                                        value={this.state.phoneNumber}
                                                    />
                                                </div>

                                                <div className='d-flex justify-content-between'>
                                                    <TextInput
                                                        label="Amount Per Day"
                                                        type="text"
                                                        onChange={() => this.onChangeAmountPerDay}
                                                        value={this.state.amountPerDay}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <TextInput
                                                        label="Tin Number"
                                                        type="text"
                                                        onChange={() => this.onChangeTinNumber}
                                                        value={this.state.tinNumber}
                                                    />
                                                </div>

                                                <br />

                                                <div className='d-flex justify-content-between'>
                                                    <SelectSearch
                                                        label="Select Role"
                                                        onChange={() => this.onChangeRole}
                                                        options={optRoles}
                                                        placeholder={"Select Role"}
                                                    />

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;

                                                    <SelectSearch
                                                        label="Is Union Subscribed"
                                                        onChange={() => this.onChangeIsUnionSubscribed}
                                                        options={selectOptions}
                                                        placeholder={"Is union subscribed"}
                                                    />
                                                </div>

                                                <br />



                                                {/* <ImageUpload
                                                    label="Upload profile picture"
                                                    id="fileToUpload"
                                                    name="fileToUpload"
                                                    onChange={() => this.onChangeImage}
                                                /> */}

                                                <button type="submit" onClick={this.onSubmit} className="btn btn-purple mr-2">Submit</button>
                                                <button class="btn btn-light" onClick={this.onClickExit}>Cancel</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="col-xl-9 grid-margin-lg-0 grid-margin stretch-card">
                                    <div class="row">

                                        <div className="col-sm-12 col-xl-12 col-lg-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>
                                                    <form method="post" onSubmit={this.onSubmit}>

                                                        <TextInput
                                                            label="First Name"
                                                            type="text"
                                                            onChange={() => this.onChangeFirstName}
                                                            value={this.state.firstName}
                                                        />

                                                        <TextInput
                                                            label="Last Name"
                                                            type="text"
                                                            onChange={() => this.onChangeLastName}
                                                            value={this.state.lastName}
                                                        />

                                                        <TextInput
                                                            label="Username"
                                                            type="text"
                                                            onChange={() => this.onChangeUserName}
                                                            value={this.state.userName}
                                                        />

                                                        <TextInput
                                                            label="Phone Number"
                                                            type="text"
                                                            onChange={() => this.onChangePhoneNumber}
                                                            value={this.state.phoneNumber}
                                                        />

                                                        <TextInput
                                                            label="Email Address"
                                                            type="text"
                                                            onChange={() => this.onChangeEmail}
                                                            value={this.state.email}
                                                        />

                                                        <SelectSearch
                                                            label="Select Role"
                                                            onChange={() => this.onChangeRole}
                                                            options={optRoles}
                                                            placeholder={"Select Role"}
                                                        />

                                                        <ImageUpload
                                                            label="Upload profile picture"
                                                            id="fileToUpload"
                                                            name="fileToUpload"
                                                            onChange={() => this.onChangeImage}
                                                        />

                                                        <div className="modal-footer">
                                                            <input
                                                                className="btn ripple btn-success"
                                                                type="button"
                                                                value="Create user"
                                                                onClick={this.onSubmit}
                                                            />
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <Ratings /> */}

                                <div class="col-xl-3 p-0">
                                    <div class="d-flex justify-content-between flex-column">

                                        <DashCard
                                            name="Total Users"
                                            icon="fas fa-user"
                                            color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                                            percentage="All system users"
                                            value={this.state.countUsers}
                                            href="#"
                                        />

                                        <RecentActivity />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        );

    }
}

export default CreateUser;
