import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem('payslip@user');

const apiHost = dictionary.apiHost

export default {

    async createRole(roleName, code) {

        let data = {
            "role_name": roleName,
            "code": code,
            // "permission_id": permissionId
        }
        try {
            let response = await fetch(apiHost + 'user/role.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateRole(role_id, role_name, code) {

        let data = {
            "role_name": role_name,
            "role_id": role_id,
            "code": code
        }
        try {
            let response = await fetch(apiHost + 'user/role.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRoles(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/role/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async deleteRole(role_id, user_id) {

        let data = {
            "role_id": role_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/role.delete',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

}
