import React from 'react'
import TableHeader from '../../shared/TableHeader'
import ajaxPayslips from '../../utils/remote/ajaxPayslips'
import functions from '../../utils/functions'
import ActivitySpinner from '../common/ActivitySpinner'
import NoContent from '../common/NoContent'
import ActivityLoader from '../common/ActivityLoader'


class RecentPayslips extends React.Component {

    state = {
        userId: functions.sessionGuard(),

        payslipList: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        recordView: false,
        noOfRecords: 8,

        metaData: false,
        currentPage: 1,
        pagination: false,

        countActivePayslips: '...',
        tableID: "table-payslips"
    }

    componentDidMount() {
        this.listRecentPayslips()
    }

    listRecentPayslips = async () => {
        const server_response = await ajaxPayslips.listRecentPayslips(this.state.status, this.state.currentPage);
        if (server_response.status === "OK") {
            this.setState({
                payslipList: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })
        } else {
            this.setState({
                payslipList: "404",
                metaData: false
            })
        }
    }

    render() {

        const payslipList = this.state.payslipList;

        return (

            <div className="card">
                <div className="card-body recent-pay">
                    <TableHeader
                        title="Recent Generated Payslips"
                        subtitle="A representation of the recent generated payslip activity."
                        view="View All"
                        link="/payslips"
                        // onClick={this.onClickViewAll}
                    />

                    <div className='scroll-widge overflow-auto'>
                        <div class="table-responsive mt-3">
                            <table class="table text-nowrap table-hover table-header-bg">
                                <thead>
                                    <tr>
                                        {/* <th>Payslip Ref</th> */}
                                        <th></th>
                                        <th>Full Name</th>
                                        <th>Days Worked</th>
                                        <th>Gross Amount (UGX)</th>
                                        <th>Deductions</th>
                                        <th>Net Pay</th>
                                        <th>Date issued </th>
                                        {/* <th>Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {payslipList && payslipList !== "404" &&
                                        payslipList.map((item, key) =>
                                            <tr>
                                                <td className="pointer">
                                                    <div className="main-img-user">
                                                        <i className="fa fa-sticky-note text-primary" style={{ fontSize: "37px" }}></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className='font-weight-bold'>{item.user.full_name}</p>
                                                    <small className='tx-12 tx-gray-500'>@{item.user.username}</small>
                                                </td>
                                                <td className='text-center'>{item.total_days}</td>
                                                <td>{item.gross_pay}</td>
                                                <td>{item.total_deductions}</td>
                                                <td className='font-weight-bold'>{item.net_pay}</td>
                                                <td>
                                                    <p>{item.created_at.short_date}</p>
                                                    <small className='text-gray'>{item.created_at.time}</small>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-center align-items-center'>
                            {!payslipList && <ActivityLoader />}
                            {payslipList === "404" && <NoContent />}
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RecentPayslips
