import React from 'react'
import TableHeader from '../../shared/TableHeader'
// import { Link } from 'react-router-dom'


class RecentActivity extends React.Component {

  render() {
    return (

      <div className=" col-sm-12 grid-margin">
        <div className="card">
          <div className="card-body">

            <TableHeader
              title="Recent Activity"
              subtitle="All the recent transactions."
            // view="View All"
            // onClick={this.props.onClickViewAll}
            />

            <div className="row">
              <div className="col-sm-12">
                <div className="text-dark">

                  <div className="d-flex pb-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Payslips have been generated</div>
                      <div className="text-muted font-weight-normal mt-1">32 Minutes Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">20</h6>
                    </div>
                  </div>

                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>You have logged in</div>
                      <div className="text-muted font-weight-normal mt-1">45 Minutes Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">1</h6>
                    </div>
                  </div>

                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>New users have been added</div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">12</h6>
                    </div>
                  </div>

                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>New payslips generated</div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">5</h6>
                    </div>
                  </div>

                  <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>New payslips generated</div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">5</h6>
                    </div>
                  </div>

                  {/* <div className="d-flex pb-3 pt-3 border-bottom justify-content-between">
                    <div className="mr-3"><i className="mdi mdi-signal-cellular-outline icon-md"></i></div>
                    <div className="font-weight-bold mr-sm-4">
                      <div>Deposit has updated to Paid </div>
                      <div className="text-muted font-weight-normal mt-1">1 Days Ago</div>
                    </div>
                    <div>
                      <h6 className="font-weight-bold text-info ml-sm-2">$5391</h6>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }

}

export default RecentActivity
