import React from 'react';
import functions from '../../utils/functions';
import { Link } from 'react-router-dom';
import ajaxUser from '../../utils/remote/ajaxUser';
import ActivityLoader from '../../components/common/ActivityLoader';
import AlertError from '../../shared/AlertError';
// import { RenderSecure } from '../../utils/script/RenderSecure';
import AlertSuccess from '../../shared/AlertSuccess';
// import ConfigurationContext from '../../Context/ConfigurationContext';
import dictionary from '../../utils/dictionary';
import Title from '../../shared/Title';
import SideBar from '../../shared/sidebar/SideBar';
import Footer from '../../shared/footer/Footer';
import PopAction from '../../components/pops/PopAction';
import NavBar from '../../shared/navbar/NavBar';
import $ from 'jquery';

import profile_photo from '../../assets/avatar.svg'


class UserProfile extends React.Component {
    // static contextType = ConfigurationContext;

    state = {
        userId: functions.sessionGuard(),
        selectedUserId: this.props.userId,
        userInfo: false,
        // username: this.props.username,

        firstName: '',
        lastName: '',
        username: '',
        photo: '',
        roleName: '',
        phoneNumber: '',
        email: '',

        toast: false,
        loading: false,
        currentOption: "PROFILE",
        permissionList: false,
        mdl: false,
        tableId: "table-user-permissions",
        tabIndex: 1,
        info: false,
        userClampList: "",
        clampStatusId: "",
        createdFrom: "",
        createdTo: "",
        info2: "",
        clampPreview: ""


    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/users/all-users');
        } else {
            this.setState({
                username: this.props.location.state.username
            }, () => {
                this.getUserInfo()
            })
        }
    }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    getUserInfo = async () => {
        const server_response = await ajaxUser.getUserByUsername(this.state.username);

        console.log(server_response)

        if (server_response.status === "OK") {

            this.setState({
                userInfo: server_response.details.content
            })
        } else {
            this.setState({
                userInfo: false
            })
        }
    }


    onClickResetPassword = () => {
        this.setState({
            mdl: false
        }, () => {
            this.setState({
                mdl: <PopAction
                    id="modal_password_reset"
                    title="Are you sure you want to reset password for user?"
                    description="Make sure the user is not currently logged in"
                    buttonLabel="Confirm Reset"
                    onConfirm={() => this.resetPassword()} />
            }, () => functions.openPopup("modal_password_reset"))
        })
    }


    resetPassword = async () => {
        const server_response = await ajaxUser.requestPasswordReset(this.state.username);
        functions.closePopup("modal_password_reset")
        if (server_response.status === "OK") {
            this.setState({
                info: <AlertSuccess message={server_response.details.message} />
            })
        } else {
            this.setState({
                info: <AlertError message={server_response.details.message} />
            })
        }
    }


    buttons = () => {
        return (
            <>
                {/* <RenderSecure code="U-USER-PASSWORD-0"> */}
                <button className="btn ripple btn-danger" onClick={this.onClickResetPassword}>
                    <i className="fa fa-lock"></i> Reset Password
                </button>
                {/* </RenderSecure> */}
            </>
        )
    }



    render() {

        const userInfo = this.state.userInfo;

        return (
            <div className="container-scroller">

                <NavBar />

                <div className="container-fluid page-body-wrapper">
                    {this.state.mdl}
                    {this.state.toast}
                    <SideBar />
                    {this.state.mdl}
                    {this.state.toast}

                    <div className="main-panel">
                        <div className="content-wrapper">

                            <Title
                                title={this.state.username}
                                description="JANUARY 20, 2023"
                                previousPageTitle="All System Users"
                                back="/users"
                                buttons={<this.buttons />}
                            />

                            {this.state.info}

                            {userInfo && userInfo !== "404" &&
                                <div className="row mt-3">
                                    <div className="col-lg-4 col-md-12 grid-margin-lg-0 stretch-card">
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <div className="user-profile mt-3">
                                                    <div className="user-image">
                                                        <img src={profile_photo} alt="profile" />
                                                    </div>
                                                    <div className="item-user pro-user">
                                                        <h4 className="pro-user-username font-weight-bold text-dark mt-4 mb-1">{userInfo.first_name} {userInfo.last_name}</h4>
                                                        <p className="pro-user-desc text-muted mb-3">{userInfo.user_role.role_name}</p>
                                                        <p className="user-info-rating">
                                                            <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                            <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                            <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                            <Link to="#"><i className="fa fa-star text-warning"> </i></Link>
                                                            <Link to="#"><i className="far fa-star text-warning"> </i></Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-8 col-md-12 grid-margin-lg-0 stretch-card">
                                        <div className="card main-content-body-profile">

                                            <div className="card-body h-100">

                                                <div className="card-header mb-4">
                                                    <h6 className="card-title my-2">User Information</h6>
                                                </div>

                                                <div className="tab-pane active" id="tab1over">
                                                    <div className="main-content-label tx-13 mg-b-20">
                                                        Personal Information
                                                    </div>
                                                    <div className="table-responsive ">
                                                        <table className="table row table-borderless">
                                                            <tbody className="col-lg-12 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Full Name :</strong> {userInfo.first_name} {userInfo.last_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Branch :</strong> {userInfo.user_branch == false ? "No branch assigned" : userInfo.user_branch.branch_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Date of birth :</strong> {userInfo.date_of_birth}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody className="col-lg-12 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Email :</strong> {userInfo.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Phone :</strong> +{userInfo.phone_number}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Username :</strong> {userInfo.username}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="main-content-label tx-13 mg-b-20 mt-5">
                                                        Payment Information
                                                    </div>
                                                    <div className="table-responsive ">
                                                        <table className="table row table-borderless">
                                                            <tbody className="col-lg-12 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Amount per day :</strong> {userInfo.amount_per_day}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>TIN :</strong> {userInfo.tin_number}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody className="col-lg-12 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Is union subscribed :</strong> {userInfo.is_union_subscribed === "1" ? "Yes" : "No"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Role :</strong> {userInfo.user_role.role_name}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <div className="main-content-label tx-13 mg-b-20 mt-3">
                                                        Other Information
                                                    </div>
                                                    <div className="table-responsive ">
                                                        <table className="table row table-borderless">
                                                            <tbody className="col-lg-12 col-xl-6 p-0">
                                                                
                                                                
                                                            </tbody>
                                                            <tbody className="col-lg-12 col-xl-6 p-0">
                                                                <tr>
                                                                    <td><strong>Is union subscribed :</strong> {userInfo.is_union_subscribed === "1" ? "Yes" : "No"}</td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div> */}
                                                </div>

                                                {/* <div className="main-profile-contact-list main-profile-work-list">
                                                    <div className="media">
                                                        <div className="media-logo bg-light text-dark">
                                                            <i className="fe fe-smartphone"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>Mobile</span>
                                                            <div>{userInfo.phone_number}</div>
                                                        </div>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-logo bg-light text-dark">
                                                            <i className="fe fe-mail"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>Username</span>
                                                            <div>{userInfo.username}</div>
                                                        </div>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-logo bg-light text-dark">
                                                            <i className="fe fe-map-pin"></i>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>Current Address</span>
                                                            <div>Kampala, Uganda</div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {!userInfo && <ActivityLoader />}


                        </div>

                        <Footer />
                    </div>

                </div>

            </div>


        );

    }
}

export default UserProfile
