import React from 'react';
import Select from 'react-select';


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    //borderBottom: '2px dotted green',
    color: state.isSelected ? '#FFFFFF' : 'black',
    //backgroundColor: state.isSelected ? '#1c1c38' : 'white',
    // color: '#000000',
    textAlign: 'left',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    // marginTop: "5%",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000000'
  })
}

class TextInput extends React.Component {


  render() {

    const inputType = this.props.type || "text"

    return (
      <>
        {/* <div className="row row-xs align-items-center mg-b-20">
          <div className="col-md-3 text-left">
            <label className="mg-b-0">{this.props.label}</label>
          </div>
          <div className="col-md-9 mg-t-5 mg-md-t-0">
            {inputType !== "textarea" &&
              <input
                type={inputType || "text"}
                className="form-control"
                placeholder={this.props.placeholder || this.props.label}
                onChange={this.props.onChange()}
                value={this.props.value}
                autoFocus={this.props.autoFocus}
                readOnly={this.props.readonly || false} 
              />
            }

            {inputType === "textarea" &&
              <textarea
                type={inputType}
                className="form-control"
                placeholder={this.props.placeholder || this.props.label}
                onChange={this.props.onChange()}
                value={this.props.value}
                autoFocus={this.props.autoFocus}
                readOnly={this.props.readonly || false} 
              />
            }


          </div>
        </div> */}

        <div className="form-group w-100">
          <label htmlFor={this.props.htmlFor}>{this.props.label}</label>

          {inputType !== "textarea" && inputType !== "select" &&
            <>
              <input
                type={inputType || "text"}
                className="form-control"
                value={this.props.value}
                id={this.props.id}
                placeholder={this.props.placeholder || this.props.label}
                onChange={this.props.name ? this.props.onChange : this.props.onChange()}
                name={this.props.name}
                autoFocus={this.props.autoFocus}
                readOnly={this.props.readonly || false}
                required={this.props.required || false}
              />

              {this.props.infoc && <span className="mt-5"> {this.props.infoc} </span>}
            </>
          }

          {inputType === "textarea" &&
            <textarea
              type={inputType}
              className="form-control"
              id={this.props.id}
              rows={this.props.rows}
              placeholder={this.props.placeholder || this.props.label}
              onChange={this.props.name ? this.props.onChange : this.props.onChange()}
              readOnly={this.props.readonly || false}
              required={this.props.required || false}
              name={this.props.name}
            />
          }

          {inputType === "select" &&
            <Select
              options={this.props.options}
              className={this.props.className || " form-input"}
              onChange={this.props.onChange}
              placeholder={this.props.placeholder || this.props.label}
              value={this.props.value}
              styles={customStyles}
            />
          }
        </div>
      </>
    );


  }
}

export default TextInput;