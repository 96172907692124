import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from '../../Context/ThemeContext';


const TableTools = () => {

    const { tableDetected, refreshDatatable } = useContext(ThemeContext);

    const onClickTableTools = () => {
        refreshDatatable()
    }

    return (
        <>
            
                {tableDetected &&
                    <Link to="#" className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center mt-1" onClick={onClickTableTools}>
                        <i className="far fa-file tx-light"></i>
                    </Link>
                }

            {/* <li className="nav-item dropdown d-flex">
                {tableDetected &&
                    <a className="nav-link count-indicator  d-flex align-items-center justify-content-center"
                         href="#" onClick={onClickTableTools}>
                        <i className="fa fa-file-excel"></i>
                    </a>
                }
            </li> */}
        </>
    );

}

export default TableTools;
