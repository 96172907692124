import React, { useEffect, useState } from 'react';
import dictionary from '../utils/dictionary';
import { beautifyTable, restoreTable } from '../utils/script/customTables';


const ThemeContext = React.createContext();

export const ThemeConsumer = ThemeContext.Consumer;

export const ThemeProvider = (props) => {

    const [refreshRate, setRefreshRate] = useState(1);
    const [tableRowCount, setTableRowCount] = useState(0);
    const [tableDetected, setTableDetected] = useState(false);
    const [viewAsRole, setViewAsRole] = useState("Normal View");
    const [displayMessage, setDisplayMessage] = useState(false)
    const [tableID, setTableID] = useState("");


    useEffect(() => {
        if (displayMessage) {
            setTimeout(() => {
                setDisplayMessage(false)
            }, 36000);
        }
    }, [displayMessage])

    useEffect(() => {

        setTimeout(() => {
            setRefreshRate(refreshRate + 1)
        }, dictionary._uiRefreshRate);

    }, [refreshRate])

    useEffect(() => {
        detectDataTable();
    }, [refreshRate])


    useEffect(() => {
        refreshDatatable();
        // console.log(tableID)
    }, [tableDetected,tableID])


    // useEffect(() => {
    //     refreshDatatable()
    // }, [tableRowCount])

    const detectDataTable = () => {
        var tableElement = document.getElementsByClassName("datatable");
        // var emptyElement = document.getElementsByClassName("dataTables_empty");

        if (tableElement.length > 0) {
            setTableRowCount(document.getElementsByTagName("tbody")[0].rows.length)
            setTableDetected(true)
            // console.log("Datatable detected with " + numRows + " data row(s)")
            // beautifyTable(tableElement[0].getAttribute('id'), true, false)
        } else {
            setTableDetected(false)
        }
    }

    const refreshDatatable = () => {
        if (tableRowCount > 0) {
            var tableElement = document.getElementsByClassName("datatable");//check if datatable exists
            if (tableElement.length > 0) {

                const eformats = tableElement[0].getAttribute('export-format');
                // const title = tableElement[0].getAttribute('title');
                // const longTitle = "Multiplex Limited \nKampala, Uganda \nP.O.Box 3874 \nHOTLINE: 0312360100 \nmultiplexug@gmail.com";
                const header = "Multiplex Limited";
                const address = "Kampala, Uganda";
                const box = "P.O.Box 3874";
                const telephone = "HOTLINE: 0312360100";
                const email = "multiplexug@gmail.com"

                // const title = () => {
                //     return longTitle.replace(/\n/g, "<br />")
                // }

                // const title = longTitle.split('\n').map(i => {
                //     return <p>{i}</p>
                // });

                const title = header;
                const messageTop = tableElement[0].getAttribute('messageTop');
                const messageBottom = tableElement[0].getAttribute('messageBottom');

                var formats = false
                var new_formarts = [];

                if (eformats !== null) {
                    formats = eformats.split(",")
                    const styledFormats = dictionary._styledExportBtnFormats
                    for (var i in formats) {
                        switch (formats[i]) {
                            case "copy":
                                new_formarts.push(styledFormats.copy)
                                break;
                            case "excel":
                                new_formarts.push(styledFormats.excel)
                                break;
                            case "csv":
                                new_formarts.push(styledFormats.csv)
                                break;
                            case "pdf":
                                styledFormats.pdf.title = title
                                styledFormats.pdf.messageTop = messageTop
                                styledFormats.pdf.messageBottom = messageBottom
                                new_formarts.push(styledFormats.pdf)
                                break;
                            case "print":
                                new_formarts.push(styledFormats.print)
                                break;
                            default:
                                new_formarts = new_formarts
                                break;

                        }//end switch
                    }
                }
                //check if it has already been initialized
                if (document.getElementsByClassName("dataTables_wrapper").length <= 0) {
                    const b = beautifyTable(tableElement[0].getAttribute('id'), true, new_formarts)
                } else {
                    restoreTable(tableElement[0].getAttribute('id'))
                }
            }
        }
    }




    return (
        <ThemeContext.Provider
            value={{
                tableDetected,
                viewAsRole,
                displayMessage,
                setDisplayMessage,
                refreshDatatable,
                setViewAsRole,
                setTableID
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
}


export default ThemeContext;