import React from 'react';
// import LeftSideBar from '../../../components/common/LeftSideBar';
import Footer from '../../shared/footer/Footer';
// import TopBar from '../../../components/common/TopBar';
// import PageHeader from '../../../components/common/PageHeader';
import functions from '../../utils/functions';
// import RightSideBar from '../../../components/common/RightSideBar';
// import ConfigMenu from '../../components/configMenu';
// import ajax from '../../utils/ajax';
// import ActivityLoader from '../../../components/ActivityLoader'
import TextInput from '../../components/common/TextInput';
import dictionary from '../../utils/dictionary';
import AlertSuccess from '../../shared/AlertSuccess';
import AlertError from '../../shared/AlertError';
import SelectSearch from '../../shared/SelectSearch';
import ImageUpload from '../../shared/ImageUpload';
import ActivityLoader from '../../components/common/ActivityLoader';
import NavBar from '../../shared/navbar/NavBar';
import SideBar from '../../shared/sidebar/SideBar';
import Title from '../../shared/Title';
import ajaxUser from '../../utils/remote/ajaxUser';
import ajaxRoles from '../../utils/remote/ajaxRoles';
import DashCard from '../../components/dashboard/cards/DashCard';
import RecentActivity from '../../components/dashboard/RecentActivity';
import ajaxPayslips from '../../utils/remote/ajaxPayslips';

class UpdatePayslip extends React.Component {

  _isMounted = false;

  state = {
    userId: functions.sessionGuard(),
    roleId: '',
    unionSubId: '',

    week1: '',
    week2: '',
    week3: '',
    week4: '',
    week5: '',
    advDeduction: '',
    createdFor: '',
    editedPayslipId: '',

    allRoles: false,
    payslipRecord: false,
    roleName: '',
    fileName: '',
    profilePhoto: '',
    editedUserId: '',
    // info: '',

    countPayslips: '...',
  }

  componentDidMount() {
    // this._isMounted = true;

    // this.fetchRoles();

    this.timerCountPayslips = setTimeout(this.funcPayslipCount = async () => {
      await this.countPayslips();
      this.timerCountPayslips = setTimeout(this.funcPayslipCount, dictionary._waitTime); // (*)
    }, dictionary._waitTime)

    if (!this.props.location.state) {
      this.props.history.push('/payslips');
    } else {
      this.setState({
        payslipRecord: this.props.location.state.record
      }, () => {

        this.setState({
          payslipId: this.state.payslipRecord.payslip_id,
          userId: this.state.payslipRecord.user_id,
          week1: this.state.payslipRecord.week1,
          week2: this.state.payslipRecord.week2,
          week3: this.state.payslipRecord.week3,
          week4: this.state.payslipRecord.week4,
          week5: this.state.payslipRecord.week5,
          // unionFeeDeduction: this.state.payslipRecord.union_fee_deduction,
          advDeduction: this.state.payslipRecord.adv_deduction,
          // amountPerDay: this.state.payslipRecord.amount_per_day,
          // totalDeductions: this.state.payslipRecord.total_deductions,
          // totalDays: this.state.payslipRecord.total_days,
          // payeDeduction: this.state.payslipRecord.paye_deduction,

          // nssf_deduction: this.state.payslipRecord.nssf_deduction,
          // net_pay: this.state.payslipRecord.net_pay,
          // lst_deduction: this.state.payslipRecord.lst_deduction,
          // gross_pay: this.state.payslipRecord.gross_pay,
          createdFor: this.state.payslipRecord.created_for,
          editedPayslipId: this.state.payslipRecord.payslip_id,
          editedUserId: this.state.payslipRecord.user.full_name,
          // user_Id: userRecord.user_id
        })

      })
    }

  }


  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerCountPayslips)
  }

  countPayslips = async () => {
    const server_response = await ajaxPayslips.countPayslips(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        countPayslips: server_response.details.content.total_c
      })
    } else {
      this.setState({
        countPayslips: "..."
      })
    }
  }


  onChangeWeek1 = (event) => {
    this.setState({ week1: event.target.value })
  }

  onChangeWeek2 = (event) => {
    this.setState({ week2: event.target.value })
  }

  onChangeWeek3 = (event) => {
    this.setState({ week3: event.target.value })
  }

  onChangeWeek4 = (event) => {
    this.setState({ week4: event.target.value })
  }

  onChangeWeek5 = (event) => {
    this.setState({ week5: event.target.value })
  }

  onChangeAdvanceDeduction = (event) => {
    this.setState({ advDeduction: event.target.value })
  }

  onChangePayslipMonth = (selectedValue) => {
    this.setState({
      createdFor: selectedValue.value
    })
  }


  onClickExit = () => {
    this.props.history.push('/payslips');
  }


  fetchRoles = async () => {
    const server_response = await ajaxRoles.getRoles(this.state.userId);

    if (server_response.status === "OK") {
      this.setState({
        allRoles: server_response.details.content.list
      })
    } else {
      this.setState({
        allRoles: "404"
      })
    }
  }


  onSave = async (event) => {
    event.preventDefault();

    this.setState({
      info: <ActivityLoader />
    })

    const {
      week1,
      week2,
      week3,
      week4,
      week5,
      advDeduction,
      createdFor,
      editedPayslipId
    } = this.state;

    if (
      // roleName.length>0 && roleId.length>0
      week1.length > 0 ||
      week2.length > 0 ||
      week3.length > 0 ||
      // email.length > 0 ||
      week4.length > 0 ||
      week5.length > 0 ||
      // unionSubId.length > 0 ||
      advDeduction.length > 0 ||
      createdFor.length > 0
    ) {
      //fetch login from the API
      const server_response = await ajaxPayslips.updatePayslip(
        editedPayslipId,
        week1,
        week2,
        week3,
        week4,
        week5,
        advDeduction,
        createdFor,
        this.state.userId,
      );

      console.log(server_response)
      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._requireRoleName} />,
        loading: ''
      })
    }


  }

  render() {

    // const allRoles = this.state.allRoles;
    // const optRoles = [];

    // if (allRoles && allRoles !== "404") {
    //   allRoles.map((item, key) =>
    //     optRoles.push({
    //       label: item.role_name,
    //       value: item.role_id
    //     }))
    // }

    const selectMonths = [
      { value: 'January', label: 'January' },
      { value: 'Feburary', label: 'Feburary' },
      { value: 'March', label: 'March' },
      { value: 'April', label: 'April' },
      { value: 'May', label: 'May' },
      { value: 'June', label: 'June' },
      { value: 'July', label: 'July' },
      { value: 'August', label: 'August' },
      { value: 'September', label: 'September' },
      { value: 'October', label: 'October' },
      { value: 'November', label: 'November' },
      { value: 'December', label: 'December' },
    ]


    const optUnionSub = [
      { value: 1, label: 'Yes' },
      { value: 0, label: 'No' }
    ]

    return (
      <div className="container-scroller">
        <NavBar />

        <div className="container-fluid page-body-wrapper">
          <SideBar />

          <div className="main-panel">
            <div className="content-wrapper">

              <Title
                title="Update Payslip"
                description="JANUARY 20, 2023"
                previousPageTitle="All System Payslips"
                back="/payslips"
              />

              <div className="row mt-3">
                <div className="col-xl-9 grid-margin-lg-0 stretch-card">
                  <div className="card">
                    <div className="card-body">

                      <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>

                      <h5 className='mb-4'>Payslip for: <span className="font-weight-bold text-black">{this.state.editedUserId}</span></h5>

                      <hr className='mb-5'/>

                      <form method="post" onSubmit={this.onSave} className="forms-sample">
                        <div className='d-flex justify-content-around'>
                          <TextInput
                            label="Week1"
                            type="text"
                            htmlFor="exampleInputName1"
                            id="exampleInputName1"
                            onChange={() => this.onChangeWeek1}
                            value={this.state.week1}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Week2"
                            type="text"
                            htmlFor="exampleInputName1"
                            id="exampleInputName1"
                            onChange={() => this.onChangeWeek2}
                            value={this.state.week2}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Week3"
                            type="text"
                            htmlFor="exampleInputName1"
                            id="exampleInputName1"
                            onChange={() => this.onChangeWeek3}
                            value={this.state.week3}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Week4"
                            type="text"
                            htmlFor="exampleInputName1"
                            id="exampleInputName1"
                            onChange={() => this.onChangeWeek4}
                            value={this.state.week4}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Week5"
                            type="text"
                            htmlFor="exampleInputName1"
                            id="exampleInputName1"
                            onChange={() => this.onChangeWeek5}
                            value={this.state.week5}
                          />
                        </div>

                        {/* <TextInput
                                                    label="Amount per day"
                                                    type="text"
                                                    htmlFor="exampleInputName1"
                                                    id="exampleInputName1"
                                                    onChange={() => this.onChangeAmountPerDay}
                                                    value={this.state.payslipAmountPerDay}
                                                /> */}

                        <SelectSearch
                          label="Month Of Payment"
                          onChange={() => this.onChangePayslipMonth}
                          options={selectMonths}
                          placeholder={this.state.createdFor}
                        />

                        <br />

                        {/* <div className='d-flex justify-content-around'> */}

                        <TextInput
                          label="Advanced Deduction"
                          type="text"
                          htmlFor="exampleInputName1"
                          id="exampleInputName1"
                          onChange={() => this.onChangeAdvanceDeduction}
                          value={this.state.advDeduction}
                        />

                        {/* &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp; */}


                        {/* <TextInput
                                                        label="Month Of Payment"
                                                        type="text"
                                                        htmlFor="exampleInputName1"
                                                        id="exampleInputName1"
                                                        onChange={() => this.onChangePayslipMonth}
                                                        value={this.state.payslipMonth}
                                                    /> */}

                        {/* </div> */}

                        <button type="submit" onClick={this.onSubmit} className="btn btn-purple mr-2">Submit</button>
                        <button className="btn btn-light" onClick={this.onClickExit}>Cancel</button>
                      </form>




                    </div>
                  </div>
                </div>

                <div className="col-xl-3 p-0">
                  <div className="d-flex justify-content-between flex-column">

                    <DashCard
                      name="Total Payslips"
                      icon="fas fa-book"
                      color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                      percentage="Generated payslips "
                      value={this.state.countPayslips}
                      href="#"
                    />

                    <RecentActivity />
                  </div>
                </div>

              </div>

            </div>
            <Footer />
          </div>
        </div>
      </div>


    );

  }
}

export default UpdatePayslip
