import React from 'react';
// import LeftSideBar from '../../../components/common/LeftSideBar';
import Footer from '../../shared/footer/Footer';
// import TopBar from '../../../components/common/TopBar';
// import PageHeader from '../../../components/common/PageHeader';
import functions from '../../utils/functions';
// import RightSideBar from '../../../components/common/RightSideBar';
// import ConfigMenu from '../../components/configMenu';
// import ajax from '../../utils/ajax';
// import ActivityLoader from '../../../components/ActivityLoader'
import TextInput from '../../components/common/TextInput';
import dictionary from '../../utils/dictionary';
import AlertSuccess from '../../shared/AlertSuccess';
import AlertError from '../../shared/AlertError';
import SelectSearch from '../../shared/SelectSearch';
import ImageUpload from '../../shared/ImageUpload';
import ActivityLoader from '../../components/common/ActivityLoader';
import NavBar from '../../shared/navbar/NavBar';
import SideBar from '../../shared/sidebar/SideBar';
import Title from '../../shared/Title';
import ajaxUser from '../../utils/remote/ajaxUser';
import ajaxRoles from '../../utils/remote/ajaxRoles';
import DashCard from '../../components/dashboard/cards/DashCard';
import RecentActivity from '../../components/dashboard/RecentActivity';
import ajaxBranch from '../../utils/remote/ajaxBranch';

class UpdateSysUser extends React.Component {

  _isMounted = false;

  state = {
    userId: functions.sessionGuard(),
    roleId: '',
    branchId: '',
    unionSubId: '',

    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    amountPerDay: '',
    tinNumber: '',
    isUnionSubscribed: '',

    allRoles: false,
    allBranches: false,
    userRecord: false,
    roleName: '',
    branchName: '',
    fileName: '',
    profilePhoto: '',
    editedUserId: '',
    // info: '',

    countUsers: '...',
  }

  componentDidMount() {
    // this._isMounted = true;

    this.fetchRoles();
    this.fetchBranches();

    this.timerCountUsers = setTimeout(this.funcUserCount = async () => {
      await this.countUsers();
      this.timerCountUsers = setTimeout(this.funcUserCount, dictionary._waitTime); // (*)
    }, dictionary._waitTime)

    if (!this.props.location.state) {
      this.props.history.push('/sys-users');
    } else {
      this.setState({
        userRecord: this.props.location.state.record
      }, () => {
        console.log(this.state.userRecord)
        this.setState({
          roleId: this.state.userRecord.user_role.role_id,
          roleName: this.state.userRecord.user_role.role_name,
          branchId: this.state.userRecord.branch.branch_id,
          branchName: this.state.userRecord.branch.branch_name,
          firstName: this.props.location.state.record.first_name,
          lastName: this.state.userRecord.last_name,
          userName: this.state.userRecord.username,
          email: this.state.userRecord.email,

          phoneNumber: this.state.userRecord.phone_number,
          dateOfBirth: this.state.userRecord.date_of_birth,
          amountPerDay: this.state.userRecord.amount_per_day,
          isUnionSubscribed: this.state.userRecord.is_union_subscribed==1?"Yes":"No",
          unionSubId: this.state.userRecord.is_union_subscribed,
          tinNumber: this.state.userRecord.tin_number,
          allRoles: false,
          editedUserId: this.state.userRecord.user_id,
          // user_Id: userRecord.user_id
        })

      })
    }

  }


  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerCountUsers)
  }

  countUsers = async () => {
    const server_response = await ajaxUser.countUsers(this.state.userId);
    if (server_response.status === "OK") {
      this.setState({
        countUsers: server_response.details.content.total_c,
      })
    } else {
      this.setState({
        countUsers: "...",
      })
    }
  }


  // onChangeRoleName=(event)=>{
  //     this.setState({roleName:event.target.value})
  // }

  onChangeFirstName = (event) => {
    this.setState({ firstName: event.target.value })
  }

  onChangeLastName = (event) => {
    this.setState({ lastName: event.target.value })
  }

  onChangeUserName = (event) => {
    this.setState({ userName: event.target.value })
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value })
  }

  onChangePhoneNumber = (event) => {
    this.setState({ phoneNumber: event.target.value })
  }

  onChangeDateOfBirth = (event) => {
    this.setState({ dateOfBirth: event.target.value })
  }

  onChangeAmountPerDay = (event) => {
    this.setState({ amountPerDay: event.target.value })
  }

  onChangeTinNumber = (event) => {
    this.setState({ tinNumber: event.target.value })
  }

  onChangeRole = (selectedRole) => {
    this.setState({
      roleId: selectedRole.value
    })
  }

  onChangeBranch = (selectedBranch) => {
    this.setState({
      branchId: selectedBranch.value
    })
  }

  onChangeIsUnionSubscribed = (selectedUnionSub) => {
    this.setState({
      unionSubId: selectedUnionSub.value
    })
  }

  onClickExit = () => {
    this.props.history.push('/sys-users');
  }


  fetchRoles = async () => {
    const server_response = await ajaxRoles.getRoles(this.state.userId);

    if (server_response.status === "OK") {
      this.setState({
        allRoles: server_response.details.content.list
      })
    } else {
      this.setState({
        allRoles: "404"
      })
    }
  }

  fetchBranches = async () => {
    const server_response = await ajaxBranch.listBranches(this.state.userId);

    if (server_response.status === "OK") {
      this.setState({
        allBranches: server_response.details.content
      })
    } else {
      this.setState({
        allBranches: "404"
      })
    }
  }


  onSave = async (event) => {
    event.preventDefault();

    this.setState({
      info: <ActivityLoader />
    })

    const {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      dateOfBirth,
      amountPerDay,
      tinNumber,
      unionSubId,
      roleId,
      branchId,
      editedUserId
    } = this.state;

    if (
      // roleName.length>0 && roleId.length>0
      firstName.length > 0 ||
      lastName.length > 0 ||
      userName.length > 0 ||
      // email.length > 0 ||
      phoneNumber.length > 0 ||
    //   amountPerDay.length > 0 ||
      // unionSubId.length > 0 ||
      roleId.length > 0 ||
    //   branchId.length > 0 ||
      editedUserId.length > 0
    ) {
      //fetch login from the API
      const server_response = await ajaxUser.updateUser(
        editedUserId,
        firstName,
        lastName,
        userName,
        phoneNumber,
        roleId,
        branchId,
        email,
        amountPerDay,
        unionSubId,
        dateOfBirth,
        tinNumber,
        this.state.userId,
      );

      if (server_response.status === "OK") {
        this.setState({
          info: <AlertSuccess message={server_response.details.message} />
        })

      } else {

        this.setState({
          info: <AlertError message={server_response.details.message} />
        })

      }

    } else {
      this.setState({
        info: <AlertError message={dictionary._requireRoleName} />,
        loading: ''
      })
    }


  }

  render() {

    const allRoles = this.state.allRoles;
    const allBranches = this.state.allBranches;
    const optRoles = [];
    const optBranches = [];

    if (allRoles && allRoles !== "404") {
      allRoles.map((item, key) =>
        optRoles.push({
          label: item.role_name,
          value: item.role_id
        }))
    }

    if (allBranches && allBranches !== "404") {
      allBranches.map((item, key) =>
      optBranches.push({
          label: item.branch_name,
          value: item.branch_id
        }))
    }

    const optUnionSub = [
      { value: 1, label: 'Yes' },
      { value: 0, label: 'No' }
    ]

    return (
      <div className="container-scroller">
        <NavBar />

        <div className="container-fluid page-body-wrapper">
          <SideBar />

          <div className="main-panel">
            <div className="content-wrapper">

              <Title
                title="Update System User"
                description="JANUARY 20, 2023"
                previousPageTitle="All System Users"
                back="/sys-users"
              />

              <div className="row mt-3">
                <div className="col-xl-9 grid-margin-lg-0 stretch-card">
                  <div className="card">
                    <div className="card-body">

                      <div className="d-flex align-items-center justify-content-center text-center m-2">{this.state.info}</div>

                      <form method="post" onSubmit={this.onSave} className="forms-sample">
                        <div className='d-flex justify-content-between'>
                          <TextInput
                            label="First Name"
                            type="text"
                            onChange={() => this.onChangeFirstName}
                            value={this.state.firstName}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Last Name"
                            type="text"
                            onChange={() => this.onChangeLastName}
                            value={this.state.lastName}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Username"
                            type="text"
                            onChange={() => this.onChangeUserName}
                            value={this.state.userName}
                          />
                        </div>

                        <div className='d-flex justify-content-between'>

                          <TextInput
                            label="Phone Number"
                            type="text"
                            onChange={() => this.onChangePhoneNumber}
                            value={this.state.phoneNumber}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Email Address"
                            type="text"
                            onChange={() => this.onChangeEmail}
                            value={this.state.email}
                          />

                          {/* &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Date Of Birth"
                            type="text"
                            onChange={() => this.onChangeDateOfBirth}
                            value={this.state.dateOfBirth}
                          /> */}
                        </div>

                        {/* <div className='d-flex justify-content-between'>
                          <TextInput
                            label="Amount Per Day"
                            type="text"
                            onChange={() => this.onChangeAmountPerDay}
                            value={this.state.amountPerDay}
                          />

                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <TextInput
                            label="Tin Number"
                            type="text"
                            onChange={() => this.onChangeTinNumber}
                            value={this.state.tinNumber}
                          />
                        </div> */}

                        <br />

                        <div className='d-flex justify-content-between'>
                          <SelectSearch
                            label="Select Role"
                            onChange={() => this.onChangeRole}
                            options={optRoles}
                            placeholder={this.state.roleName}
                          />

                          {/* &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <SelectSearch
                            label="Branch"
                            onChange={() => this.onChangeBranch}
                            options={optBranches}
                            placeholder={this.state.branchName}
                          /> */}

                          {/* &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          &nbsp;

                          <SelectSearch
                            label="Is Union Subscribed"
                            onChange={() => this.onChangeIsUnionSubscribed}
                            options={optUnionSub}
                            placeholder={this.state.isUnionSubscribed}
                          /> */}
                        </div>

                        <br />

                        <div className="modal-footer">
                          <input className="btn ripple btn-purple" type="button" value="Save Changes" onClick={this.onSave} />
                          {/* <button type="submit" onClick={this.onSubmit} className="btn btn-primary mr-2">Submit</button> */}
                          <button className="btn btn-light" onClick={this.onClickExit}>Cancel</button>
                        </div>
                      </form>




                    </div>
                  </div>
                </div>

                <div className="col-xl-3 p-0">
                  <div className="d-flex justify-content-between flex-column">

                    <DashCard
                      name="Total Users"
                      icon="fas fa-user"
                      color="icon-service rounded-circle bg-ssezi-transparent text-ssezi"
                      percentage="All system users"
                      value={this.state.countUsers}
                      href="#"
                    />

                    <RecentActivity />
                  </div>
                </div>

              </div>

            </div>
            <Footer />
          </div>
        </div>
      </div>


    );

  }
}

export default UpdateSysUser
