import React, { Component } from 'react';

class CompanyHeader extends Component {

    render() {
        return (
            <>
                <p className="h3">Multiplex Limited</p>
                <address>
                    P.O. Box 3874, Kampala, Uganda<br/>
                    HOTLINE: 0312360100<br/>
                    multiplexug@gmail.com
                </address>
            </>
        );
    }
}

export default CompanyHeader;
