import React from 'react';
import { TailSpin } from 'react-loader-spinner';

class ActivityLoader extends React.Component {


  render() {

    const types = ['Circles', 'Audio', 'Ball-Triangle', 'Bars', 'Grid', 'Hearts', 'Oval', 'Puff', 'Rings', 'TailSpin', 'ThreeDots']
    const type = this.props.type || 0
    const height = this.props.height || 50
    const width = this.props.width || 80
    const color = this.props.color || "#e86f35"
    const customClass = this.props.customClass || "col-xl-12 col-lg-12 col-12 text-center mt-5 mb-3 d-flex justify-content-center align-items-center"
    
    return (
      <div className={customClass}>
        <TailSpin
          type={types[type]}
          color={color}
          height={height}
          width={width}
        />
      </div>
    );


  }
}

export default ActivityLoader
