import React from 'react'
import { Link } from 'react-router-dom'
import TableHeader from '../../shared/TableHeader'
import functions from '../../utils/functions'
import ajaxUser from '../../utils/remote/ajaxUser'

import avatar from '../../assets/avatar.svg';
import NoContent from '../../components/common/NoContent';
import ActivitySpinner from '../common/ActivitySpinner'
import ActivityLoader from '../common/ActivityLoader'


class RecentUsers extends React.Component {

    state = {
        userId: functions.sessionGuard(),

        userlist: false,
        sessionPreview: '',
        activeSessionId: false,
        toast: false,
        filter: false,
        conductors: false,
        status: '',
        locationPIN: false,
        warnSessionEnd: false,
        dialogue: "",
        recordView: false,
        noOfRecords: 8,

        metaData: false,
        currentPage: 1,
        pagination: false,

        countActivePayslips: '...',
        tableID: "table-payslips"
    }

    componentDidMount() {
        this.listRecentUsers()
    }

    listRecentUsers = async () => {
        const server_response = await ajaxUser.listRecentUsers(this.state.status, this.state.currentPage);
        if (server_response.status === "OK") {
            this.setState({
                userlist: server_response.details.content.list,
                metaData: server_response.details.content.meta
            })
        } else {
            this.setState({
                userlist: "404",
                metaData: false
            })
        }
    }

    render() {

        const userslist = this.state.userlist;

        return (

            <div className="card">
                <div className="card-body recent-pay">
                    <TableHeader
                        title="Recent Payslip Staff"
                        subtitle="A representation of the recently added users activity."
                        view="View All"
                        link="/fld-users"
                        // onClick={this.onClickViewAll}
                    />

                    <div className='scroll-widge overflow-auto'>
                        <div class="table-responsive mt-3">
                            <table class="table text-nowrap table-hover table-header-bg">
                                <thead>
                                    <tr>
                                        {/* <th>Payslip Ref</th> */}
                                        {/* <th>ID</th> */}
                                        <th></th>
                                        <th>Staff Name</th>
                                        
                                        <th>Role</th>
                                        <th>Phone Number</th>

                                        {/* <th>Email Address</th> */}
                                        
                                        {/* <th>Status</th> */}
                                        <th>Branch</th>
                                        <th>Amount per day</th>
                                        <th>Created on</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userslist && userslist !== "404" &&
                                        userslist.map((item, key) =>
                                            <tr key={key}>
                                                {/* <td>{item.user_id}</td> */}

                                                <td className="pointer">
                                                    <div className="main-img-package">
                                                        <img alt="avatar" src={avatar} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className='font-weight-bold'>{item.full_name}</p>
                                                    <small className="tx-12 tx-gray-500">@{item.username}</small>
                                                </td>
                                                
                                                <td>{item.user_role.role_name}</td>
                                                <td>{item.phone_number}</td>


                                                {/* <td className="cell_size">{item.email}</td> */}
                                                {/* <td className="cell_size">{item.branch}</td> */}
                                                
                                                {/* <td>
                                                                        <label class="badge badge-danger">Pending</label>
                                                                        {item.status === "1" && <span className="badge badge-success">Active</span>}
                                                                        {item.status === "0" && <span className="badge badge-secondary">Ended</span>}
                                                                    </td> */}
                                                <td>{item.branch?item.branch.branch_name : <span className='tx-12 tx-gray-500'>Not attached</span>}</td>
                                                <td className='font-weight-bold'>{item.amount_per_day}</td>
                                                <td>
                                                    <p>{item.created_at.date}</p>
                                                    <small className="tx-12 tx-gray-500">{item.created_at.time}</small>
                                                </td>
                                            </tr>
                                        )
                                    }

                                    
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-center align-items-center'>
                            {!userslist && <ActivityLoader />}
                            {userslist === "404" && <NoContent />}
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RecentUsers
