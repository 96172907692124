import React from 'react';
import { Link } from 'react-router-dom';

// import you from '../../assets/icons/youtube.png'
// import insta from '../../assets/icons/insta.png'
// import face from '../../assets/icons/face.png'
// import associate from '../../assets/images/association.png'
// import asso from '../../assets/images/asso.png'
// import tweet from '../../assets/icons/twitter.png'
// import ioni from '../../assets/icons/email.png'
// import './footer.css'
// import FooterNav from '../MobileNav/footerNav';
// import logo from '../../assets/ssezibwalogo.png';


class Title extends React.Component {

    render() {

        return (


            <div className="d-flex justify-content-between align-items-center">
                <div className="mb-4 mb-xl-0">

                    <h4 className="font-weight-bold text-dark">{this.props.title}</h4>
                    {/* <p className="font-weight-normal mb-2 text-muted">{this.props.description}</p> */}

                    {this.props.previousPageTitle &&
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={this.props.back}>{this.props.previousPageTitle}</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{this.props.title}</li>
                        </ol>
                    }

                </div>

                <div className='d-flex mb-4 mb-xl-0'>
                    {this.props.add &&
                        <Link type="button" className="btn btn-purple d-flex align-items-center" to={this.props.link} onClick={this.props.onClick}>
                            <i className={`${this.props.icon} mr-2`}></i> {this.props.add}
                        </Link>
                    }

                    {this.props.buttons}

                    {this.props.import &&
                        <a className="btn btn-primary d-flex align-items-center ml-2" onClick={this.props.import}>
                            <i className="fas fa-cloud-upload-alt mr-2"></i> Import
                        </a>
                    }
                </div>
            </div>


        )
    }
}

export default Title;
